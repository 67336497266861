import React from "react";

const StatesTerritoriesList = () => {
  return (
    <React.Fragment>
      <option value="-1">Select One</option>
      <option value="5">AL - ALABAMA</option>
      <option value="4">AK - ALASKA</option>
      <option value="8">AS - AMERICAN SAMOA</option>
      <option value="9">AZ - ARIZONA</option>
      <option value="7">AR - ARKANSAS</option>
      <option value="2">AA - Armed Forces Americas (Except Canada)</option>
      <option value="3">AE - Armed Forces Europe</option>
      <option value="6">AP - Armed Forces Pacific</option>
      <option value="10">CA - CALIFORNIA</option>
      <option value="11">CO - COLORADO</option>
      <option value="12">CT - CONNECTICUT</option>
      <option value="14">DE - DELAWARE</option>
      <option value="13">DC - DISTRICT OF COLUMBIA</option>
      {window.location.pathname === '/sender-info'?<React.Fragment/>:  <option value="16">FM - FEDERATED STATES OF MICRONESIA</option>}
      <option value="15">FL - FLORIDA</option>
      <option value="17">GA - GEORGIA</option>
      <option value="18">GU - GUAM</option>
      <option value="19">HI - HAWAII</option>
      <option value="21">ID - IDAHO</option>
      <option value="22">IL - ILLINOIS</option>
      <option value="23">IN - INDIANA</option>
      <option value="20">IA - IOWA</option>
      <option value="24">KS - KANSAS</option>
      <option value="25">KY - KENTUCKY</option>
      <option value="26">LA - LOUISIANA</option>
      <option value="29">ME - MAINE</option>
     {window.location.pathname === '/sender-info'?<React.Fragment/>: <option value="30">MH - MARSHALL ISLANDS</option>}
      <option value="28">MD - MARYLAND</option>
      <option value="27">MA - MASSACHUSETTS</option>
      <option value="31">MI - MICHIGAN</option>
      <option value="32">MN - MINNESOTA</option>
      <option value="35">MS - MISSISSIPPI</option>
      <option value="33">MO - MISSOURI</option>
      <option value="36">MT - MONTANA</option>
      <option value="39">NE - NEBRASKA</option>
      <option value="43">NV - NEVADA</option>
      <option value="40">NH - NEW HAMPSHIRE</option>
      <option value="41">NJ - NEW JERSEY</option>
      <option value="42">NM - NEW MEXICO</option>
      <option value="44">NY - NEW YORK</option>
      <option value="37">NC - NORTH CAROLINA</option>
      <option value="38">ND - NORTH DAKOTA</option>
      <option value="34">MP - NORTHERN MARIANA ISLANDS</option>
      <option value="45">OH - OHIO</option>
      <option value="46">OK - OKLAHOMA</option>
      <option value="47">OR - OREGON</option>
      {window.location.pathname === '/sender-info'?<React.Fragment/>: <option value="50">PW - PALAU</option>}
      <option value="48">PA - PENNSYLVANIA</option>
      <option value="49">PR - PUERTO RICO</option>
      <option value="51">RI - RHODE ISLAND</option>
      <option value="52">SC - SOUTH CAROLINA</option>
      <option value="53">SD - SOUTH DAKOTA</option>
      <option value="54">TN - TENNESSEE</option>
      <option value="55">TX - TEXAS</option>
      <option value="56">UT - UTAH</option>
      <option value="59">VT - VERMONT</option>
      <option value="57">VA - VIRGINIA</option>
      <option value="58">VI - VIRGIN ISLANDS</option>
      <option value="60">WA - WASHINGTON</option>
      <option value="62">WV - WEST VIRGINIA</option>
      <option value="61">WI - WISCONSIN</option>
      <option value="63">WY - WYOMING</option>
    </React.Fragment>
  );
};

export default StatesTerritoriesList;
