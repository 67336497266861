import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Autocomplete from "./Subcomponents/Autocomplete";
import * as analytics from "../Components/Analytics/Analytics.js";
// Images
import caretRight from "../customs_forms/images/caret-right.svg";
import alert from "../customs_forms/images/alert.svg";
import LoggedInNav from "./LoggedInNav";
import InputFields from "./Subcomponents/InputFields";
var dataLayer = {
  event: "application",
  element: "",
  selection: "",
  page: "cfo-step-1a-select-destination-international"
};

class FlowTypeInputs extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.stepNumberCallback(2);
    this.props.setPageErrors([]);
    this.props.toggleLoadingBar(false);
    if (this.previousState.flowType === "other") {
      this.formattedCountryList = this.formatCountryList();
    }
    const flowTypeInputs = this.previousState.flowTypeInputs;
    if (flowTypeInputs) {
      this.state = {
        flowType:
          this.previousState.flowType === "militaryToUSA"
            ? "usa"
            : this.previousState.flowType,
        ...flowTypeInputs
      };
      delete this.state.countryId;
    } else {
      this.state = {
        flowType: this.previousState.flowType,
        senderZipCode: "",
        recipientZipCode: "",
        fetchStateZipCodeResponse: [],
        countryName:
          this.previousState.flowType === "Canada"
            ? this.previousState.flowType
            : "",
        lightDocumentsOnly: "",
        displayZipCodes: false
      };
    }
  }

  componentDidMount = () => {
    this.props.loginCheck();
  };

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.flowType
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no flow type
    window.location.replace("/index");
  };

  formatCountryList = () => {
    // Remove United States from the array
    let countryList = JSON.parse(JSON.stringify(this.props.countryList));
    for (let i in countryList) {
      if (countryList[i].countryDbId === 840) {
        countryList.splice(i, 1);
        break;
      }
    }
    // Return a new array containing only the country names
    let newCountryList = [];
    for (let i in countryList) {
      newCountryList.push(countryList[i].countryName);
    }
    return newCountryList;
  };

  handleFlowTypeInputs = (event) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    });
  };

  handleBackButton = () => {
    dataLayer.element = "Back";
    analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
    this.props.history.push("/flow-type", {
      previousState: {
        ...this.previousState,
        flowType:
          this.previousState.flowType === "militaryToUSA"
            ? "usa"
            : this.previousState.flowType
      }
    });
  };

  // From weight to zip codes (non-international flows)
  handleFromWeight = () => {
    this.setState({
      displayZipCodes: true
    });
  };

  // From zip codes to weight (non-international flows)
  handleToWeight = () => {
    this.setState({
      displayZipCodes: false
    });
  };

  // Get the country input from the autocomplete component
  // (for the non-Canadian international flow)
  getDataFromChild = (countryName, isCountryValid) => {
    this.setState({
      countryName: countryName,
      isCountryValid: isCountryValid
    });
  };

  handleContinueButton = (e) => {
    e.preventDefault();
    if (
      this.state.flowType === "other" &&
      this.state.countryName.toUpperCase() === "CANADA"
    ) {
      // If user is in the non-Canadian international flow but selects Canada
      // and clicks continue, modify state as though the user selected Canada
      // as the flow type on the previous page
      // (thereby prompting for sender and recipient zip codes)
      this.setState({
        flowType: "Canada"
      });
    } else if (this.state.flowType === "usa") {
      // If user is in the military/territory to USA flow, need to distinguish
      // between military and territory origins before proceeding
      this.props.toggleLoadingBar(true);
      axios
        .get(
          "/CFOApplication/fetchCityStateByZipCode?zipCode=" +
            this.state.senderZipCode.trim(),
          {
            headers: { "Content-Type": "application/json" }
          }
        )
        .then((response) => {
          const militaryToUsa = ["AA", "AE", "AP"];
          this.updatePreviousState(
            militaryToUsa.includes(response.data.address.state)
              ? "militaryToUSA"
              : "usa"
          );
        })
        .catch((error) => {
          console.log(error);
          this.props.setPageErrors([
            "We're sorry. An unexpected error has occurred."
          ]);
          this.props.toggleLoadingBar(false);
        });
    } else {
      // Otherwise, update stored state and proceed normally to the next page
      this.updatePreviousState();
    }
  };

  updatePreviousState = (newFlowType) => {
    this.previousState.flowType = newFlowType || this.state.flowType;
    this.previousState.flowTypeInputs = {
      senderZipCode: this.state.senderZipCode.trim(),
      recipientZipCode: this.state.recipientZipCode.trim(),
      countryName: this.state.countryName.trim(),
      countryId: this.state.countryName.trim() ? this.getCountryId() : "",
      lightDocumentsOnly: this.state.lightDocumentsOnly.trim(),
      displayZipCodes: false
    };
    dataLayer.selection = this.state.countryName.trim();
    dataLayer.element = "Continue";
    analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
    this.props.history.push("/prohibitions", {
      previousState: this.previousState
    });
  };

  getCountryId = () => {
    let countryList = this.props.countryList;
    for (let i in countryList) {
      if (
        countryList[i].countryName.toUpperCase() ===
        this.state.countryName.trim().toUpperCase()
      ) {
        return countryList[i].countryDbId.toString();
      }
    }
    return "";
  };

  renderPostalCodes = () => {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8">
                      <div className="row section-row">
                        <div className="col-md-6">
                          <div className="row section-row">
                            <div className="col-12">
                              <h2>
                                Enter Sender ZIP Code<sup>&trade;</sup>
                              </h2>
                            </div>
                          </div>
                          <div className="row section-row">
                            <div className="col-12">
                              <p>
                                Please enter your ZIP Code<sup>&trade;</sup>.
                              </p>
                            </div>
                          </div>
                          <div className="row section-row">
                            <InputFields
                              outerDivClassList="col-12"
                              isRequired={true}
                              inputId="senderZipCode"
                              labelText="Sender ZIP Code&trade;"
                              inputClassList="form-control"
                              inputType="text"
                              inputPlaceholder="e.g. 20001"
                              inputMaxLength="5"
                              inputValue={this.state.senderZipCode}
                              inputOnChange={this.handleFlowTypeInputs}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 md-border-left">
                          <div className="row section-row">
                            <div className="col-12">
                              <h2>Enter Recipient Postal Code</h2>
                            </div>
                          </div>
                          <div className="row section-row">
                            <div className="col-12">
                              <p>Please enter the recipient Postal Code.</p>
                            </div>
                          </div>
                          <div className="row section-row">
                            <InputFields
                              outerDivClassList="col-12"
                              isRequired={true}
                              inputId="recipientZipCode"
                              labelText="Recipient Postal Code"
                              inputClassList="form-control"
                              inputType="text"
                              inputPlaceholder="e.g. M4B 2J8"
                              inputMaxLength="7"
                              inputValue={this.state.recipientZipCode}
                              inputOnChange={this.handleFlowTypeInputs}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              <div className="form-button-container">
                                <button
                                  className="previous btn btn-outline-primary"
                                  type="button"
                                  onClick={this.handleBackButton}
                                >
                                  Back
                                </button>
                              </div>
                              {this.state.senderZipCode.trim().length === 5 &&
                              this.state.recipientZipCode.trim().length >= 6 ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={this.handleContinueButton}
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  };

  renderDestination = () => {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-12">
                      <h2>
                        What international destination are you shipping to?
                      </h2>
                      <p>*Please enter an international destination:</p>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="selected-note">
                          You are shipping to:
                        </div>

                        <Autocomplete
                          userInput={this.state.countryName}
                          suggestionList={this.formattedCountryList}
                          parentCallback={this.getDataFromChild}
                          type="text"
                          name="country"
                          className="form-control"
                          id="country-select"
                          isRequired={true}
                          placeholder="Type in your destination"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              <div className="form-button-container">
                                <button
                                  className="previous btn btn-outline-primary"
                                  type="button"
                                  onClick={this.handleBackButton}
                                >
                                  Back
                                </button>
                              </div>
                              {this.state.isCountryValid ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={this.handleContinueButton}
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  };

  renderWeight = () => {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-12">
                      <h2>
                        Are you shipping only documents that weigh under a pound
                        using Priority Mail or Ground Advantage?
                      </h2>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8 col-xl-7">
                      <p>
                        *Please indicate if you are shipping only documents that
                        weigh under a pound using Priority Mail or Ground
                        Advantage.
                      </p>
                      <div className="radio-button">
                        <label>
                          <input
                            type="radio"
                            name="lightDocumentsOnly"
                            className="has-dependent"
                            data-dependent="1"
                            value="1"
                            checked={this.state.lightDocumentsOnly === "1"}
                            onChange={this.handleFlowTypeInputs}
                          />
                          <span> Yes</span>
                        </label>
                      </div>
                      <div className="radio-button">
                        <label>
                          <input
                            required="required"
                            type="radio"
                            name="lightDocumentsOnly"
                            className="has-dependent"
                            data-dependent="0"
                            value="0"
                            checked={this.state.lightDocumentsOnly === "0"}
                            onChange={this.handleFlowTypeInputs}
                          />
                          <span> No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {this.state.lightDocumentsOnly === "1" ? (
                    <div className="row section-row" id="row-dependent-1">
                      <div className="col-12">
                        <div className="row section-row">
                          <div className="col-12">
                            <p>
                              <img src={alert} alt="!" /> A customs form is not
                              required for your shipment. You may use{" "}
                              <a
                                href="https://cns.usps.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Click-N-Ship
                              </a>
                              <sup>&reg;</sup> to send your package.
                            </p>
                          </div>
                        </div>
                        <div className="row section-row">
                          <div className="col-12">
                            <p>
                              <a
                                href="https://cns.usps.com/"
                                className="btn btn-primary"
                              >
                                Exit
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row section-row" id="row-dependent-0">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              {this.state.lightDocumentsOnly !== "1" ? (
                                <div className="form-button-container">
                                  <button
                                    className="previous btn btn-outline-primary"
                                    type="button"
                                    onClick={this.handleBackButton}
                                  >
                                    Back
                                  </button>
                                </div>
                              ) : null}
                              {this.state.lightDocumentsOnly === "0" ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={this.handleFromWeight}
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  };

  renderZipCodes = () => {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8">
                      <div className="row section-row">
                        <div className="col-md-6">
                          <div className="row section-row">
                            <div className="col-12">
                              <h2>
                                Enter Sender ZIP Code<sup>&trade;</sup>
                              </h2>
                            </div>
                          </div>
                          <div className="row section-row">
                            <div className="col-12">
                              <p>
                                Please enter your ZIP Code<sup>&trade;</sup>.
                              </p>
                            </div>
                          </div>
                          <div className="row section-row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="inline-label-wrapper">
                                  <label
                                    className="is-required"
                                    htmlFor="senderZipCode"
                                  >
                                    Sender ZIP Code<sup>&trade;</sup>
                                  </label>
                                </div>
                                <input
                                  name="senderZipCode"
                                  id="senderZipCode"
                                  className="form-control"
                                  type="text"
                                  required="required"
                                  placeholder="e.g. 20001"
                                  maxLength="5"
                                  value={this.state.senderZipCode}
                                  onChange={this.handleFlowTypeInputs}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 md-border-left">
                          <div className="row section-row">
                            <div className="col-12">
                              <h2>
                                Enter Recipient ZIP Code<sup>&trade;</sup>
                              </h2>
                            </div>
                          </div>
                          <div className="row section-row">
                            <div className="col-12">
                              <p>
                                Please enter the recipient ZIP Code
                                <sup>&trade;</sup>.
                              </p>
                            </div>
                          </div>
                          <div className="row section-row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="inline-label-wrapper">
                                  <label
                                    className="is-required"
                                    htmlFor="recipientZipCode"
                                  >
                                    Recipient ZIP Code<sup>&trade;</sup>
                                  </label>
                                </div>
                                <input
                                  name="recipientZipCode"
                                  id="recipientZipCode"
                                  className="form-control"
                                  type="text"
                                  required="required"
                                  placeholder="e.g. 00939"
                                  maxLength="5"
                                  value={this.state.recipientZipCode}
                                  onChange={this.handleFlowTypeInputs}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              <div className="form-button-container">
                                <button
                                  className="previous btn btn-outline-primary"
                                  type="button"
                                  onClick={this.handleToWeight}
                                >
                                  Back
                                </button>
                              </div>
                              {this.state.senderZipCode.trim().length === 5 &&
                              this.state.recipientZipCode.trim().length ===
                                5 ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={this.handleContinueButton}
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  };

  render() {
    switch (this.state.flowType) {
      case "Canada":
        return <React.Fragment>{this.renderPostalCodes()}</React.Fragment>;
      case "other":
        return <React.Fragment>{this.renderDestination()}</React.Fragment>;
      default:
        if (!this.state.displayZipCodes) {
          return <React.Fragment>{this.renderWeight()}</React.Fragment>;
        } else {
          return <React.Fragment>{this.renderZipCodes()}</React.Fragment>;
        }
    }
  }
}

export default withRouter(FlowTypeInputs);
