import React from "react";
import { withRouter } from "react-router-dom";
import * as analytics from "../Components/Analytics/Analytics.js";

// Images
import caretRight from "../customs_forms/images/caret-right.svg";

import LoggedInNav from "./LoggedInNav";
var dataLayer = {
  event: "vpvCFO",
  element: "",
  selection: "",
  page: "cfo-step-1-select-destination-where-shipping"
};
class FlowType extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.stepNumberCallback(1);
    this.props.setPageErrors([]);
    this.state = {
      flowType: this.previousState ? this.previousState.flowType : ""
    };
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState
    ) {
      return this.props.history.location.state.previousState;
    } else {
      return null;
    }
  };

  handleFlowType = (event) => {
    const value = event.currentTarget.value;
    this.setState({
      flowType: value
    });
  };

  handleBackButton = () => {
    dataLayer.element = "Back";
    analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
    this.props.history.push("/index");
  };

  componentDidMount = () => {
    this.props.loginCheck();
  };

  handleContinueButton = () => {
    if (
      this.previousState &&
      this.previousState.flowType !== this.state.flowType
    ) {
      delete this.previousState.flowTypeInputs;
    }
    this.previousState = {
      ...this.previousState,
      flowType: this.state.flowType
    };

    dataLayer.element = "Continue";
    dataLayer.selection = this.previousState.flowType;
    if (this.previousState.flowType === "other") {
      dataLayer.selection = "International";
    }
    if (this.previousState.flowType === "military") {
      dataLayer.selection = "Military";
    }
    if (this.previousState.flowType === "territories") {
      dataLayer.selection = "TFAS";
    }
    if (this.previousState.flowType === "usa") {
      dataLayer.selection = "United States";
    }
    analytics.setDestinationPricePackageDataLayerInfo(dataLayer);
    this.props.history.push("/flow-type-inputs", {
      previousState: this.previousState
    });

    // analytics.createLabelDataLayer("canada");
  };

  render() {
    return (
      <React.Fragment>
        <div id="cfo-wrapper">
          <div id="inner">
            <main className="section" id="customs-form-2019-main">
              <section className="section" id="app-form">
                <div className="container">
                  <form
                    className="customs-form"
                    id="customs-form"
                    action="#"
                    method="post"
                  >
                    <div className="row section-row align-items-center">
                      <div className="col-lg-4">
                        <h1>Customs Form</h1>
                        <p className="note">* indicates a required field</p>
                      </div>
                      <div className="col-lg-8">
                        {this.props.loggedIn ? (
                          <LoggedInNav />
                        ) : (
                          <ul className="title-links">
                            <li>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                              >
                                FAQs{" "}
                                <span>
                                  <img src={caretRight} alt="open link" />
                                </span>
                              </a>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="row section-row">
                      <div className="col-12">
                        <h2>Where are you shipping to?</h2>
                      </div>
                    </div>
                    <div className="row section-row">
                      <div className="col-lg-7">
                        <p>*Please select a destination:</p>
                        <div className="radio-button">
                          <label>
                            <div className="radio-input">
                              <input
                                data-alt-next-path="step02c"
                                type="radio"
                                name="destination"
                                value="Canada"
                                checked={this.state.flowType === "Canada"}
                                onChange={this.handleFlowType}
                              />
                            </div>
                            <div className="radio-label">Canada</div>
                          </label>
                        </div>
                        <div className="radio-button">
                          <label>
                            <div className="radio-input">
                              <input
                                type="radio"
                                name="destination"
                                value="other"
                                checked={this.state.flowType === "other"}
                                onChange={this.handleFlowType}
                              />
                            </div>
                            <div className="radio-label">
                              All other International Countries
                            </div>
                          </label>
                        </div>
                        <div className="radio-button">
                          <label>
                            <div className="radio-input">
                              <input
                                data-alt-next-path="step02us1"
                                type="radio"
                                name="destination"
                                value="military"
                                checked={this.state.flowType === "military"}
                                onChange={this.handleFlowType}
                              />
                            </div>
                            <div className="radio-label">
                              Military and Diplomatic
                              <br />
                              (APO, FPO, DPO)
                            </div>
                          </label>
                        </div>
                        <div className="radio-button">
                          <label>
                            <div className="radio-input">
                              <input
                                data-alt-next-path="step02us1"
                                type="radio"
                                name="destination"
                                value="territories"
                                checked={this.state.flowType === "territories"}
                                onChange={this.handleFlowType}
                              />
                            </div>
                            <div className="radio-label">
                              U.S. Territories and Freely Associated States
                              <br />
                              (American Samoa, Federated States of Micronesia,
                              Guam, North Mariana Islands, Puerto Rico, Republic
                              of Palau and the U.S. Virgin Islands)
                            </div>
                          </label>
                        </div>
                        <div className="radio-button">
                          <label>
                            <div className="radio-input">
                              <input
                                data-alt-next-path="step02us1"
                                type="radio"
                                name="destination"
                                value="usa"
                                checked={this.state.flowType === "usa"}
                                onChange={this.handleFlowType}
                              />
                            </div>
                            <div className="radio-label">
                              United States
                              <br />
                              (For shipments coming from the U.S. Military or
                              U.S. Territories to the 50 United States)
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row section-row">
                      <div className="col-lg-6">
                        <div className="subsection button-section">
                          <div className="row">
                            <div className="col-12">
                              <div className="customs-form-buttons">
                                <div className="form-button-container">
                                  <button
                                    className="previous btn btn-outline-primary"
                                    type="button"
                                    onClick={this.handleBackButton}
                                  >
                                    Back
                                  </button>
                                </div>
                                {this.state.flowType ? (
                                  <div className="form-button-container">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      id="submit"
                                      onClick={this.handleContinueButton}
                                    >
                                      Continue
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="col-lg-7">
                    <p className="european-union-text"></p>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(FlowType);
