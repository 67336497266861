import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import caretRight from "../customs_forms/images/caret-right.svg";
import info from "../customs_forms/images/info.svg";
import LoggedInNav from "./LoggedInNav";
import InputFields from "./Subcomponents/InputFields";
import GirthModal from "./Modals/GirthModal";
import isDomesticDestination from "../utils/isDomesticDestination";
import * as analytics from "../Components/Analytics/Analytics.js";
var dataLayer = {
  event: "application",
  element: "",
  selection: "",
  page: "cfo-step-2-package-details"
};
class PackageInfo extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.stepNumberCallback(4);
    this.props.setPageErrors([]);

    // Remove any receipt status that may have been applied on previous visits
    // to this page (this will be reapplied if the user proceeds through this
    // page without entering a weight again)
    delete this.previousState.receiptCheck1;
    // Remove selected service information that may have been applied on
    // previous visits to the service options page.  This is to prevent
    // the user from changing flows and selecting a possibly invalid service
    // the next time around
    delete this.previousState.serviceOptions;

    // Remove selected HAZMAT info that may have been applied on
    // previous visits to the HAZMAT info page.  This is to prevent
    // the user from changing flows and selecting a possibly invalid option
    // the next time around
    delete this.previousState.hazmatType;

    this.flowTypeInputs = this.previousState.flowTypeInputs;
    this.cartInfo = this.previousState.cartInfo;
    const packageInfo = this.previousState.packageInfo;
    if (this.cartInfo && packageInfo) {
      this.state = {
        ...packageInfo,
        errors: {
          totalPackageValue: ""
        }
      };
    } else {
      this.cartInfo = {
        encodedCartId: 0,
        batchNumber: 0
      };
      this.state = {
        weightPounds: "",
        weightOunces: "",
        totalPackageValue: "",
        length: "",
        width: "",
        height: "",
        packageNotRectangular: false,
        girth: "",
        errors: {
          totalPackageValue: ""
        }
      };
    }
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.flowTypeInputs
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there are no flow type inputs
    window.location.replace("/index");
  };

  componentDidMount() {
    this.props.loginCheck();
    this.props.toggleLoadingBar(true);
    axios
      .get(
        "/CFOApplication/fetchlabelInfo?cnsCartId=" +
          encodeURIComponent(this.cartInfo.encodedCartId) +
          "&flowType=" +
          this.previousState.flowType
      )
      .then((response) => {
        this.cartId = response.data.cnsCartId;
        this.strCnsCartId = response.data.strCnsCartId;
        this.cartInfo = {
          ...this.cartInfo,
          batchNumber: response.data.batchNbr
        };
        this.setState({
          cartId: this.cartId,
          batchNumber: response.data.batchNbr
        });
        if (!this.previousState.packageInfo) {
          axios
            .get("/CFOApplication/fetchUserPreferences")
            .then((response) => {
              // Only pre-populate the fields if the user is logged in
              // and has preferences set
              if (
                null !== response.data.preferencesData &&
                !response.data.loggedOut
              ) {
                this.setState({
                  weightPounds: response.data.preferencesData.pounds || "",
                  weightOunces: response.data.preferencesData.ounces || "",
                  length: response.data.preferencesData.pkgLengthQty || "",
                  width: response.data.preferencesData.pkgWidthQty || "",
                  height: response.data.preferencesData.pkgHeightQty || ""
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.setState({
                focusOnTop: true,
                hasLoaded: true
              });
              this.props.toggleLoadingBar(false);
            });
        } else {
          this.setState({
            focusOnTop: true,
            hasLoaded: true
          });
          this.props.toggleLoadingBar(false);
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.setPageErrors([
          "We're sorry. An unexpected error has occurred."
        ]);
        this.props.toggleLoadingBar(false);
      });
  }

  componentDidUpdate() {
    // Focus on the first field with an error
    if (this.state.focusOnError) {
      if (this.state.errors["totalPackageValue"]) {
        document.getElementById("totalPackageValue-a11y").focus();
      }
      this.setState({
        focusOnError: false
      });
    }
    // Focus on the top element on the page after first loading
    if (this.state.focusOnTop && this.state.hasLoaded) {
      document.getElementById("cfo-top-of-page").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
  }

  handlePackageInfo = (event) => {
    const target = event.currentTarget;
    const name = target.name;
    let value;
    if (name === "packageNotRectangular") {
      // Checkbox
      value = target.checked;
      if (value) {
        this.setState((prevState) => {
          const width = prevState.width || 0;
          const height = prevState.height || 0;
          return {
            girth: String(2 * width + 2 * height)
          };
        });
      }
    } else if (name !== "totalPackageValue") {
      // Inputs that accept integers only
      // (weight, L/W/H/G)
      value = target.value.replaceAll(/\D/g, "");
    } else {
      // Package value
      value = target.value;
    }
    this.setState((prevState) => {
      return {
        [name]: value,
        errors: {
          ...prevState.errors,
          [name]: ""
        }
      };
    });
  };

  displayPackageNotRectangular = () => {
    return (
      this.state.length > 12 || this.state.width > 12 || this.state.height > 12
    );
  };

  handleBackButton = () => {
    this.props.history.push("/prohibitions", {
      previousState: this.previousState
    });
    dataLayer.element = "Back";
    analytics.setDataLayerInfo(dataLayer);
  };

  // At this point, we don't know if the user is in a receipt flow,
  // so we only look at the radio button the user selected earlier.
  // During the transition to the next page, the receipt flag
  // may be set if no weight was entered (and if the respective
  // lookup code has receipts enabled).
  displayContinueButton = () => {
    switch (this.previousState.flowType) {
      case "Canada":
      case "other":
      case "military":
      case "territories":
      case "usa":
        if (
          !(this.state.weightPounds > 0) &&
          !(this.state.weightOunces > 0) &&
          this.props.lookupCodes["cfo.receipts.international.disabled"] ===
            "true"
        ) {
          return false;
        } else {
          return true;
        }
      case "militaryToUSA":
        if (
          !(this.state.weightPounds > 0) &&
          !(this.state.weightOunces > 0) &&
          this.props.lookupCodes["cfo.receipts.military.domestic.disabled"] ===
            "true"
        ) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  };

  handleContinueButton = (event) => {
    event.preventDefault();
    if (!this.validateInputs()) {
      const dataToSend = this.getDataToSend();
      this.props.toggleLoadingBar(true);
      axios
        .post("/CFOApplication/savePackageInfo", JSON.stringify(dataToSend), {
          headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
          this.previousState.cartInfo = {
            ...this.cartInfo,
            encodedCartId: response.data.strCnsCartId
          };
          // If the user did not enter a weight, user enters a "receipt" flow
          const receiptStatus = this.getCurrentFlowType();
          this.previousState.receiptCheck1 =
            receiptStatus === "domesticReceipt" ||
            receiptStatus === "internationalReceipt"
              ? receiptStatus
              : undefined;
          this.previousState.packageInfo = {
            weightPounds: this.state.weightPounds,
            weightOunces: this.state.weightOunces,
            totalPackageValue: this.state.totalPackageValue,
            length: this.state.length,
            width: this.state.width,
            height: this.state.height,
            packageNotRectangular:
              this.state.packageNotRectangular &&
              (this.state.length > 12 ||
                this.state.width > 12 ||
                this.state.height > 12),
            girth:
              this.state.packageNotRectangular &&
              this.state.girth &&
              (this.state.length > 12 ||
                this.state.width > 12 ||
                this.state.height > 12)
                ? this.state.girth
                : ""
          };
          dataLayer.element = "Continue";

          analytics.setDataLayerInfo(dataLayer);

          if (isDomesticDestination(this.previousState.flowType)) {
            this.props.history.push("/hazmat", {
              previousState: this.previousState,
              cartId: this.state.cartId
            });
          } else {
            this.props.history.push("/service-options", {
              previousState: this.previousState,
              cartId: this.state.cartId
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.setPageErrors([
            "We're sorry. An unexpected error has occurred."
          ]);
          this.props.toggleLoadingBar(false);
        });
    } else {
      this.setState({
        focusOnError: true
      });
    }
  };

  validateInputs = () => {
    let errorObject = {};
    if (!isDomesticDestination(this.previousState.flowType)) {
      if (!(this.state.totalPackageValue > 0)) {
        errorObject["totalPackageValue"] = "Please enter a package value.";
      }
      this.setInputErrors("errorObject", errorObject);
    }
    return Object.keys(errorObject).length > 0;
  };

  setInputErrors = (field, errorMessage) => {
    if (field === "errorObject") {
      this.setState({
        errors: errorMessage
      });
    } else {
      this.setState((prevState) => {
        return {
          errors: {
            ...prevState.errors,
            [field]: errorMessage
          }
        };
      });
    }
  };

  // Branch point:
  // User enters a "receipt" flow if no weight is entered.
  // The status of the lookup codes that disable receipt flows is not checked
  // here because the user should be prevented from reaching this point
  // without entering a weight if the lookup codes are active
  getCurrentFlowType = () => {
    if (
      Number(this.state.weightPounds) === 0 &&
      Number(this.state.weightOunces) === 0
    ) {
      if (
        this.previousState.flowType === "usa" ||
        this.previousState.flowType === "militaryToUSA" ||
        this.previousState.flowType === "military" ||
        this.previousState.flowType === "territories"
      ) {
        return "domesticReceipt";
      } else if (
        this.previousState.flowType === "Canada" ||
        this.previousState.flowType === "other"
      ) {
        return "internationalReceipt";
      } else {
        return this.previousState.flowType;
      }
    }
    return this.previousState.flowType;
  };

  getDataToSend = () => {
    return {
      cnsCartId: this.cartId,
      batchNbr: this.cartInfo.batchNumber,
      strCnsCartId: this.strCnsCartId,
      // Package info
      weightOzQty:
        Number(this.state.weightPounds) * 16 + Number(this.state.weightOunces),
      pkgValueAmt: this.state.totalPackageValue,
      largePackageInd:
        this.state.length > 12 ||
        this.state.width > 12 ||
        this.state.height > 12
          ? "Y"
          : "N",
      pkgLengthQty: this.state.length || "0",
      pkgWidthQty: this.state.width || "0",
      pkgHeightQty: this.state.height || "0",
      pkgShape:
        this.state.packageNotRectangular &&
        (this.state.length > 12 ||
          this.state.width > 12 ||
          this.state.height > 12)
          ? "NR"
          : "R",
      pkgGirthQty:
        this.state.packageNotRectangular &&
        this.state.girth &&
        (this.state.length > 12 ||
          this.state.width > 12 ||
          this.state.height > 12)
          ? this.state.girth
          : "0",
      countryName:
        this.props.location.state.previousState.flowTypeInputs.countryName,
      countryId:
        this.props.location.state.previousState.flowTypeInputs.countryId === ""
          ? 840
          : this.props.location.state.previousState.flowTypeInputs.countryId,
      senderZipCode:
        this.props.location.state.previousState.flowTypeInputs.senderZipCode,
      recipientZipCode:
        this.props.location.state.previousState.flowTypeInputs.recipientZipCode,
      // The originalFlowType is what the user selected in step 1.
      // The flowType may also be what the user selected in step 1,
      // or can indicate that a receipt flow has been entered.
      originalFlowType: this.previousState.flowType,
      flowType: this.getCurrentFlowType()
    };
  };

  renderInternational = () => {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8 col-xl-6">
                      <h2>Tell us about your package.</h2>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8 col-xl-6">
                      <p>
                        What is the total weight and total value of your
                        package?
                      </p>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-12">
                      <div className="row section-row">
                        <div className="col-lg-8 col-xl-6">
                          <div className="row section-row">
                            <div className="col-lg-4 col-6">
                              <div className="form-group">
                                <div className="inline-label-wrapper">
                                  <label
                                    className={
                                      this.props.lookupCodes[
                                        "cfo.receipts.international.disabled"
                                      ] === "true"
                                        ? "is-required"
                                        : ""
                                    }
                                    htmlFor="weightPounds"
                                  >
                                    Package Weight
                                  </label>
                                </div>
                                <div className="field-wrapper with-suffix suffix-is-3-chars">
                                  <input
                                    name="weightPounds"
                                    id="weightPounds"
                                    className="form-control numbersonly customized"
                                    type="text"
                                    placeholder="0"
                                    data-field-suffix="lbs"
                                    value={this.state.weightPounds}
                                    onChange={this.handlePackageInfo}
                                  />
                                  <span className="field-icon field-suffix">
                                    lbs
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-6">
                              <div className="form-group">
                                <div className="inline-label-wrapper">
                                  <label htmlFor="weightOunces">&nbsp;</label>
                                </div>
                                <div className="field-wrapper with-suffix suffix-is-2-chars">
                                  <input
                                    name="weightOunces"
                                    id="weightOunces"
                                    className="form-control numbersonly customized"
                                    max="15"
                                    type="text"
                                    placeholder="0"
                                    data-field-suffix="oz"
                                    value={this.state.weightOunces}
                                    onChange={this.handlePackageInfo}
                                  />
                                  <span className="field-icon field-suffix">
                                    oz
                                  </span>
                                </div>
                              </div>
                            </div>{" "}
                            <InputFields
                              outerDivClassList="col-lg-4"
                              isRequired={true}
                              errorMessage={this.state.errors.totalPackageValue}
                              includeAssistiveError={true}
                              inputId="totalPackageValue"
                              labelText="Total Package Value"
                              //infoIcon={info}
                              imgAlt="More info"
                              inputDivClassList="field-wrapper with-prefix prefix-is-1-chars"
                              fieldPrefix="$"
                              inputClassList="form-control numbersonly input-with-dollar customized"
                              inputType="number"
                              inputValue={this.state.totalPackageValue}
                              inputOnChange={this.handlePackageInfo}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-12">
                          <p>
                            If you know your package dimensions, please tell us
                            so we can provide shipping recommendations for
                            lowest price and fastest delivery.
                          </p>
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-12">
                          <div className="row section-row">
                            <div className="col-lg-8 col-xl-6">
                              <div className="row section-row">
                                <InputFields
                                  outerDivClassList="col-4"
                                  isRequired={false}
                                  inputId="length"
                                  labelText="Length"
                                  inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                                  inputClassList="form-control numbersonly has-dependent customized"
                                  inputType="text"
                                  inputPlaceholder="0"
                                  inputValue={this.state.length}
                                  inputOnChange={this.handlePackageInfo}
                                  fieldSuffix="in"
                                />
                                <InputFields
                                  outerDivClassList="col-4"
                                  isRequired={false}
                                  inputId="width"
                                  labelText="Width"
                                  inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                                  inputClassList="form-control numbersonly customized"
                                  inputType="text"
                                  inputPlaceholder="0"
                                  inputValue={this.state.width}
                                  inputOnChange={this.handlePackageInfo}
                                  fieldSuffix="in"
                                />
                                <div className="col-4">
                                  <div className="form-group">
                                    <div className="inline-label-wrapper">
                                      <label htmlFor="height">Height</label>
                                    </div>
                                    <div className="field-wrapper with-suffix suffix-is-2-chars">
                                      <input
                                        name="height"
                                        id="height"
                                        className="form-control numbersonly customized"
                                        type="text"
                                        placeholder="0"
                                        data-field-suffix="in"
                                        value={this.state.height}
                                        onChange={this.handlePackageInfo}
                                      />
                                      <span className="field-icon field-suffix">
                                        in
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-12">
                          <div className="row section-row">
                            <div className="col-12">
                              {this.displayPackageNotRectangular() ? (
                                <div
                                  className="row section-row"
                                  id="row-dependent-2"
                                >
                                  <div className="col-12">
                                    <div className="row section-row">
                                      <div className="col-12">
                                        <div className="usps-checkbox checkbox-field">
                                          <input
                                            type="checkbox"
                                            id="packageNotRectangular"
                                            name="packageNotRectangular"
                                            className="form-control checkbox-field has-dependent"
                                            data-dependent="3"
                                            checked={
                                              this.state.packageNotRectangular
                                            }
                                            onChange={this.handlePackageInfo}
                                          />
                                          <label htmlFor="packageNotRectangular">
                                            This package isn't a standard
                                            rectangular box.
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.packageNotRectangular ? (
                                      <div
                                        className="row section-row"
                                        id="row-dependent-3"
                                      >
                                        <div className="col-12">
                                          <p>
                                            Based on your package dimensions, we
                                            have calculated the girth of your
                                            package.
                                          </p>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.packageNotRectangular ? (
                                      <div
                                        className="row section-row"
                                        id="row-dependent-3"
                                      >
                                        <div className="col-12">
                                          <div className="row section-row">
                                            <div className="col-lg-8 col-xl-6">
                                              <div className="row section-row">
                                                <InputFields
                                                  outerDivClassList="col-lg-4"
                                                  isRequired={false}
                                                  inputId="girth"
                                                  labelText="Girth"
                                                  infoIcon={
                                                    <GirthModal targetName="girthWeightIcon" />
                                                  }
                                                  inputDivClassList="field-wrapper with-suffix suffix-is-2-chars"
                                                  inputClassList="form-control numbersonly customized"
                                                  inputType="text"
                                                  inputPlaceholder="0"
                                                  inputValue={this.state.girth}
                                                  inputOnChange={
                                                    this.handlePackageInfo
                                                  }
                                                  fieldSuffix="in"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              <div className="form-button-container">
                                <button
                                  className="previous btn btn-outline-primary"
                                  type="button"
                                  onClick={this.handleBackButton}
                                >
                                  Back
                                </button>
                              </div>
                              {this.displayContinueButton() ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={this.handleContinueButton}
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  };
  renderMilitaryTerritories = () => {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8 col-xl-6">
                      <h2>Tell us about your package.</h2>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8 col-xl-6">
                      <p>What is the total weight of your package?</p>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-12">
                      <div className="row section-row">
                        <div className="col-lg-8 col-xl-6">
                          <div className="row section-row">
                            <div className="col-lg-4 col-6">
                              <div className="form-group">
                                <div className="inline-label-wrapper">
                                  <label
                                    className={
                                      this.props.lookupCodes[
                                        "cfo.receipts.international.disabled"
                                      ] === "true"
                                        ? "is-required"
                                        : ""
                                    }
                                    htmlFor="weightPounds"
                                  >
                                    Package Weight
                                  </label>
                                </div>
                                <div className="field-wrapper with-suffix suffix-is-3-chars">
                                  <input
                                    name="weightPounds"
                                    id="weightPounds"
                                    className="form-control numbersonly customized"
                                    type="text"
                                    placeholder="0"
                                    data-field-suffix="lbs"
                                    value={this.state.weightPounds}
                                    onChange={this.handlePackageInfo}
                                  />
                                  <span className="field-icon field-suffix">
                                    lbs
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-6">
                              <div className="form-group">
                                <div className="inline-label-wrapper">
                                  <label htmlFor="weightOunces">&nbsp;</label>
                                </div>
                                <div className="field-wrapper with-suffix suffix-is-2-chars">
                                  <input
                                    name="weightOunces"
                                    id="weightOunces"
                                    className="form-control numbersonly customized"
                                    max="15"
                                    type="text"
                                    placeholder="0"
                                    data-field-suffix="oz"
                                    value={this.state.weightOunces}
                                    onChange={this.handlePackageInfo}
                                  />
                                  <span className="field-icon field-suffix">
                                    oz
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-12">
                          <p>
                            If you know your package dimensions, please tell us
                            so we can provide shipping recommendations for
                            lowest price and fastest delivery.
                          </p>
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-12">
                          <div className="row section-row">
                            <div className="col-lg-8 col-xl-6">
                              <div className="row section-row">
                                <div className="col-4">
                                  <div className="form-group">
                                    <div className="inline-label-wrapper">
                                      <label htmlFor="length">Length</label>
                                    </div>
                                    <div className="field-wrapper with-suffix suffix-is-2-chars">
                                      <input
                                        name="length"
                                        id="length"
                                        className="form-control numbersonly has-dependent customized"
                                        type="text"
                                        placeholder="0"
                                        data-field-suffix="in"
                                        data-dependent="2"
                                        value={this.state.length}
                                        onChange={this.handlePackageInfo}
                                      />
                                      <span className="field-icon field-suffix">
                                        in
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="form-group">
                                    <div className="inline-label-wrapper">
                                      <label htmlFor="width">Width</label>
                                    </div>
                                    <div className="field-wrapper with-suffix suffix-is-2-chars">
                                      <input
                                        name="width"
                                        id="width"
                                        className="form-control numbersonly customized"
                                        type="text"
                                        placeholder="0"
                                        data-field-suffix="in"
                                        value={this.state.width}
                                        onChange={this.handlePackageInfo}
                                      />
                                      <span className="field-icon field-suffix">
                                        in
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="form-group">
                                    <div className="inline-label-wrapper">
                                      <label htmlFor="height">Height</label>
                                    </div>
                                    <div className="field-wrapper with-suffix suffix-is-2-chars">
                                      <input
                                        name="height"
                                        id="height"
                                        className="form-control numbersonly customized"
                                        type="text"
                                        placeholder="0"
                                        data-field-suffix="in"
                                        value={this.state.height}
                                        onChange={this.handlePackageInfo}
                                      />
                                      <span className="field-icon field-suffix">
                                        in
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-12">
                          <div className="row section-row">
                            <div className="col-12">
                              {this.displayPackageNotRectangular() ? (
                                <div
                                  className="row section-row"
                                  id="row-dependent-2"
                                >
                                  <div className="col-12">
                                    <div className="row section-row">
                                      <div className="col-12">
                                        <div className="usps-checkbox checkbox-field">
                                          <input
                                            type="checkbox"
                                            id="packageNotRectangular"
                                            name="packageNotRectangular"
                                            className="form-control checkbox-field has-dependent"
                                            data-dependent="3"
                                            checked={
                                              this.state.packageNotRectangular
                                            }
                                            onChange={this.handlePackageInfo}
                                          />
                                          <label htmlFor="packageNotRectangular">
                                            This package isn't a standard
                                            rectangular box.
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.packageNotRectangular ? (
                                      <div
                                        className="row section-row"
                                        id="row-dependent-3"
                                      >
                                        <div className="col-12">
                                          <p>
                                            Based on your package dimensions, we
                                            have calculated the girth of your
                                            package.
                                          </p>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.packageNotRectangular ? (
                                      <div
                                        className="row section-row"
                                        id="row-dependent-3"
                                      >
                                        <div className="col-12">
                                          <div className="row section-row">
                                            <div className="col-lg-8 col-xl-6">
                                              <div className="row section-row">
                                                <div className="col-lg-4">
                                                  <div className="form-group">
                                                    <div className="inline-label-wrapper">
                                                      <label
                                                        className="is-required"
                                                        htmlFor="girth"
                                                      >
                                                        Girth
                                                      </label>{" "}
                                                      <div className="more-info large-info">
                                                        <span
                                                          className="more-info-link modal-trigger"
                                                          data-toggle="modal"
                                                          data-target="#modal-girth"
                                                        >
                                                          <img
                                                            className="tooltip-icon"
                                                            src={info}
                                                            alt="More info"
                                                            width="18"
                                                            height="18"
                                                          />
                                                        </span>
                                                        <div
                                                          className="more-info-content modal fade"
                                                          id="modal-girth"
                                                        >
                                                          <div
                                                            className="modal-dialog modal-lg"
                                                            role="document"
                                                          >
                                                            <div className="modal-content">
                                                              <div className="modal-body">
                                                                <p>
                                                                  Postage is
                                                                  charged by
                                                                  weight and
                                                                  distance. For
                                                                  lightweight
                                                                  large
                                                                  packages, a
                                                                  balloon rate
                                                                  may be charged
                                                                  instead.
                                                                </p>
                                                                <p>
                                                                  Parcels
                                                                  addressed to
                                                                  zones 1-4
                                                                  (including
                                                                  local) that
                                                                  weigh less
                                                                  than 20
                                                                  pounds, but
                                                                  measure more
                                                                  than 84 inches
                                                                  (but not more
                                                                  than 108
                                                                  inches) in
                                                                  combined
                                                                  length and
                                                                  girth are
                                                                  charged the
                                                                  applicable
                                                                  zone price for
                                                                  a 20 pound
                                                                  parcel.
                                                                </p>
                                                                <p>
                                                                  Dimensional
                                                                  weight is
                                                                  applied to
                                                                  parcels
                                                                  addressed to
                                                                  zones 5-8 and
                                                                  that exceed 1
                                                                  cubic foot
                                                                  (1,728 cubic
                                                                  inches) The
                                                                  dimensional
                                                                  weight
                                                                  represents the
                                                                  amount of
                                                                  space a
                                                                  package
                                                                  occupies in
                                                                  relation to
                                                                  its scale
                                                                  weight,
                                                                  therefore
                                                                  postage may be
                                                                  based on scale
                                                                  weight or
                                                                  dimensional
                                                                  weight,
                                                                  whichever is
                                                                  greater.
                                                                </p>
                                                                <p>
                                                                  These are the
                                                                  calculations
                                                                  we use to
                                                                  determine
                                                                  pricing based
                                                                  on the
                                                                  measurements
                                                                  you provide.
                                                                </p>
                                                                <p>
                                                                  <strong>
                                                                    Rectangular
                                                                    Packages
                                                                  </strong>
                                                                  <br />
                                                                  Balloon Rate -
                                                                  zones 1-4
                                                                  (includes
                                                                  local)
                                                                  <br />
                                                                  (The maximum
                                                                  allowed for
                                                                  this rate is
                                                                  108" and 70
                                                                  pounds.)
                                                                </p>
                                                                <p>
                                                                  Length + Girth
                                                                  = X"
                                                                  <br />
                                                                  Length is
                                                                  always the
                                                                  longest
                                                                  measurement.
                                                                  Girth is the
                                                                  measurement
                                                                  around the
                                                                  thickest part
                                                                  of the parcel.
                                                                </p>
                                                                <p>
                                                                  Dimensional
                                                                  Weight -
                                                                  (zones 5-8)
                                                                  <br />
                                                                  Length x Width
                                                                  x Height = X /
                                                                  194 = X pounds
                                                                  <br />
                                                                  If the result
                                                                  exceeds 1,728
                                                                  cubic inches,
                                                                  divide the
                                                                  result by 194
                                                                  and round up
                                                                  to the nearest
                                                                  whole inch to
                                                                  determine
                                                                  dimensional
                                                                  weight in
                                                                  pounds.*
                                                                </p>
                                                                <p>
                                                                  <strong>
                                                                    Non-rectangular
                                                                    Packages
                                                                  </strong>
                                                                  <br />
                                                                  Balloon Rate -
                                                                  zones 1-4
                                                                  (includes
                                                                  local)
                                                                  <br />
                                                                  (The maximum
                                                                  allowed for
                                                                  this rate is
                                                                  108"" and 70
                                                                  pounds.)
                                                                </p>
                                                                <p>
                                                                  Length + Girth
                                                                  = X"
                                                                </p>
                                                                <p>
                                                                  Dimensional
                                                                  Weight
                                                                </p>
                                                                <p>
                                                                  Length x Width
                                                                  x Height =
                                                                  X(0.785) = X /
                                                                  194 = X pounds
                                                                  <br />
                                                                  Before
                                                                  performing
                                                                  this
                                                                  calculation,
                                                                  round off
                                                                  length, width,
                                                                  and height.
                                                                  Multiply the
                                                                  result by
                                                                  0.785 (*see
                                                                  above). If the
                                                                  dimensional
                                                                  weight exceeds
                                                                  70 pounds, the
                                                                  mailer pays
                                                                  the 70 pound
                                                                  price.
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="field-wrapper with-suffix suffix-is-2-chars">
                                                      <input
                                                        name="girth"
                                                        id="girth"
                                                        className="form-control numbersonly customized"
                                                        type="text"
                                                        placeholder="0"
                                                        data-field-suffix="in"
                                                        value={this.state.girth}
                                                        onChange={
                                                          this.handlePackageInfo
                                                        }
                                                      />
                                                      <span className="field-icon field-suffix">
                                                        in
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              <div className="form-button-container">
                                <button
                                  className="previous btn btn-outline-primary"
                                  type="button"
                                  onClick={this.handleBackButton}
                                >
                                  Back
                                </button>
                              </div>
                              {this.displayContinueButton() ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={this.handleContinueButton}
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  };
  renderMilitaryToUS = () => {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                      <p className="note">* indicates a required field</p>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8 col-xl-6">
                      <h2>Tell us about your package.</h2>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-8 col-xl-6">
                      {this.props.lookupCodes[
                        "cfo.receipts.military.domestic.disabled"
                      ] === "false" ? (
                        <p>
                          Package weight is not required when shipping from the
                          U.S. Military to the United States. You may choose a
                          shipping option at the Military Post Office. If you
                          would like to see all available shipping options,
                          along with lowest price and fastest delivery, please
                          enter the weight of your package below.
                        </p>
                      ) : null}
                      <p>
                        The maximum package weight permitted by USPS is 70 lbs.
                      </p>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-12">
                      <div className="row section-row">
                        <div className="col-lg-8 col-xl-6">
                          <div className="row section-row">
                            <div className="col-lg-4 col-6">
                              <div className="form-group">
                                <div className="inline-label-wrapper">
                                  <label
                                    className={
                                      this.props.lookupCodes[
                                        "cfo.receipts.military.domestic.disabled"
                                      ] === "true"
                                        ? "is-required"
                                        : ""
                                    }
                                    htmlFor="weightPounds"
                                  >
                                    Package Weight
                                  </label>
                                </div>
                                <div className="field-wrapper with-suffix suffix-is-3-chars">
                                  <input
                                    name="weightPounds"
                                    id="weightPounds"
                                    className="form-control numbersonly customized"
                                    type="text"
                                    placeholder="0"
                                    data-field-suffix="lbs"
                                    value={this.state.weightPounds}
                                    onChange={this.handlePackageInfo}
                                  />
                                  <span className="field-icon field-suffix">
                                    lbs
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-6">
                              <div className="form-group">
                                <div className="inline-label-wrapper">
                                  <label htmlFor="weightOunces">&nbsp;</label>
                                </div>
                                <div className="field-wrapper with-suffix suffix-is-2-chars">
                                  <input
                                    name="weightOunces"
                                    id="weightOunces"
                                    className="form-control numbersonly customized"
                                    max="15"
                                    type="text"
                                    placeholder="0"
                                    data-field-suffix="oz"
                                    value={this.state.weightOunces}
                                    onChange={this.handlePackageInfo}
                                  />
                                  <span className="field-icon field-suffix">
                                    oz
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-12">
                          <p>
                            If you know your package dimensions, please tell us
                            so we can provide shipping recommendations for
                            lowest price and fastest delivery.
                          </p>
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-12">
                          <div className="row section-row">
                            <div className="col-lg-8 col-xl-6">
                              <div className="row section-row">
                                <div className="col-4">
                                  <div className="form-group">
                                    <div className="inline-label-wrapper">
                                      <label htmlFor="length">Length</label>
                                    </div>
                                    <div className="field-wrapper with-suffix suffix-is-2-chars">
                                      <input
                                        name="length"
                                        id="length"
                                        className="form-control numbersonly has-dependent customized"
                                        type="text"
                                        placeholder="0"
                                        data-field-suffix="in"
                                        data-dependent="2"
                                        value={this.state.length}
                                        onChange={this.handlePackageInfo}
                                      />
                                      <span className="field-icon field-suffix">
                                        in
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="form-group">
                                    <div className="inline-label-wrapper">
                                      <label htmlFor="width">Width</label>
                                    </div>
                                    <div className="field-wrapper with-suffix suffix-is-2-chars">
                                      <input
                                        name="width"
                                        id="width"
                                        className="form-control numbersonly customized"
                                        type="text"
                                        placeholder="0"
                                        data-field-suffix="in"
                                        value={this.state.width}
                                        onChange={this.handlePackageInfo}
                                      />
                                      <span className="field-icon field-suffix">
                                        in
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="form-group">
                                    <div className="inline-label-wrapper">
                                      <label htmlFor="height">Height</label>
                                    </div>
                                    <div className="field-wrapper with-suffix suffix-is-2-chars">
                                      <input
                                        name="height"
                                        id="height"
                                        className="form-control numbersonly customized"
                                        type="text"
                                        placeholder="0"
                                        data-field-suffix="in"
                                        value={this.state.height}
                                        onChange={this.handlePackageInfo}
                                      />
                                      <span className="field-icon field-suffix">
                                        in
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-12">
                          <div className="row section-row">
                            <div className="col-12">
                              {this.displayPackageNotRectangular() ? (
                                <div
                                  className="row section-row"
                                  id="row-dependent-2"
                                >
                                  <div className="col-12">
                                    <div className="row section-row">
                                      <div className="col-12">
                                        <div className="usps-checkbox checkbox-field">
                                          <input
                                            type="checkbox"
                                            id="packageNotRectangular"
                                            name="packageNotRectangular"
                                            className="form-control checkbox-field has-dependent"
                                            data-dependent="3"
                                            checked={
                                              this.state.packageNotRectangular
                                            }
                                            onChange={this.handlePackageInfo}
                                          />
                                          <label htmlFor="packageNotRectangular">
                                            This package isn't a standard
                                            rectangular box.
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.packageNotRectangular ? (
                                      <div
                                        className="row section-row"
                                        id="row-dependent-3"
                                      >
                                        <div className="col-12">
                                          <p>
                                            Based on your package dimensions, we
                                            have calculated the girth of your
                                            package.
                                          </p>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.packageNotRectangular ? (
                                      <div
                                        className="row section-row"
                                        id="row-dependent-3"
                                      >
                                        <div className="col-12">
                                          <div className="row section-row">
                                            <div className="col-lg-8 col-xl-6">
                                              <div className="row section-row">
                                                <div className="col-lg-4">
                                                  <div className="form-group">
                                                    <div className="inline-label-wrapper">
                                                      <label
                                                        className="is-required"
                                                        htmlFor="girth"
                                                      >
                                                        Girth
                                                      </label>{" "}
                                                      <div className="more-info large-info">
                                                        <span
                                                          className="more-info-link modal-trigger"
                                                          data-toggle="modal"
                                                          data-target="#modal-girth"
                                                        >
                                                          <img
                                                            className="tooltip-icon"
                                                            src={info}
                                                            alt="More info"
                                                            width="18"
                                                            height="18"
                                                          />
                                                        </span>
                                                        <div
                                                          className="more-info-content modal fade"
                                                          id="modal-girth"
                                                        >
                                                          <div
                                                            className="modal-dialog modal-lg"
                                                            role="document"
                                                          >
                                                            <div className="modal-content">
                                                              <div className="modal-body">
                                                                <p>
                                                                  Postage is
                                                                  charged by
                                                                  weight and
                                                                  distance. For
                                                                  lightweight
                                                                  large
                                                                  packages, a
                                                                  balloon rate
                                                                  may be charged
                                                                  instead.
                                                                </p>
                                                                <p>
                                                                  Parcels
                                                                  addressed to
                                                                  zones 1-4
                                                                  (including
                                                                  local) that
                                                                  weigh less
                                                                  than 20
                                                                  pounds, but
                                                                  measure more
                                                                  than 84 inches
                                                                  (but not more
                                                                  than 108
                                                                  inches) in
                                                                  combined
                                                                  length and
                                                                  girth are
                                                                  charged the
                                                                  applicable
                                                                  zone price for
                                                                  a 20 pound
                                                                  parcel.
                                                                </p>
                                                                <p>
                                                                  Dimensional
                                                                  weight is
                                                                  applied to
                                                                  parcels
                                                                  addressed to
                                                                  zones 5-8 and
                                                                  that exceed 1
                                                                  cubic foot
                                                                  (1,728 cubic
                                                                  inches) The
                                                                  dimensional
                                                                  weight
                                                                  represents the
                                                                  amount of
                                                                  space a
                                                                  package
                                                                  occupies in
                                                                  relation to
                                                                  its scale
                                                                  weight,
                                                                  therefore
                                                                  postage may be
                                                                  based on scale
                                                                  weight or
                                                                  dimensional
                                                                  weight,
                                                                  whichever is
                                                                  greater.
                                                                </p>
                                                                <p>
                                                                  These are the
                                                                  calculations
                                                                  we use to
                                                                  determine
                                                                  pricing based
                                                                  on the
                                                                  measurements
                                                                  you provide.
                                                                </p>
                                                                <p>
                                                                  <strong>
                                                                    Rectangular
                                                                    Packages
                                                                  </strong>
                                                                  <br />
                                                                  Balloon Rate -
                                                                  zones 1-4
                                                                  (includes
                                                                  local)
                                                                  <br />
                                                                  (The maximum
                                                                  allowed for
                                                                  this rate is
                                                                  108" and 70
                                                                  pounds.)
                                                                </p>
                                                                <p>
                                                                  Length + Girth
                                                                  = X"
                                                                  <br />
                                                                  Length is
                                                                  always the
                                                                  longest
                                                                  measurement.
                                                                  Girth is the
                                                                  measurement
                                                                  around the
                                                                  thickest part
                                                                  of the parcel.
                                                                </p>
                                                                <p>
                                                                  Dimensional
                                                                  Weight -
                                                                  (zones 5-8)
                                                                  <br />
                                                                  Length x Width
                                                                  x Height = X /
                                                                  194 = X pounds
                                                                  <br />
                                                                  If the result
                                                                  exceeds 1,728
                                                                  cubic inches,
                                                                  divide the
                                                                  result by 194
                                                                  and round up
                                                                  to the nearest
                                                                  whole inch to
                                                                  determine
                                                                  dimensional
                                                                  weight in
                                                                  pounds.*
                                                                </p>
                                                                <p>
                                                                  <strong>
                                                                    Non-rectangular
                                                                    Packages
                                                                  </strong>
                                                                  <br />
                                                                  Balloon Rate -
                                                                  zones 1-4
                                                                  (includes
                                                                  local)
                                                                  <br />
                                                                  (The maximum
                                                                  allowed for
                                                                  this rate is
                                                                  108"" and 70
                                                                  pounds.)
                                                                </p>
                                                                <p>
                                                                  Length + Girth
                                                                  = X"
                                                                </p>
                                                                <p>
                                                                  Dimensional
                                                                  Weight
                                                                </p>
                                                                <p>
                                                                  Length x Width
                                                                  x Height =
                                                                  X(0.785) = X /
                                                                  194 = X pounds
                                                                  <br />
                                                                  Before
                                                                  performing
                                                                  this
                                                                  calculation,
                                                                  round off
                                                                  length, width,
                                                                  and height.
                                                                  Multiply the
                                                                  result by
                                                                  0.785 (*see
                                                                  above). If the
                                                                  dimensional
                                                                  weight exceeds
                                                                  70 pounds, the
                                                                  mailer pays
                                                                  the 70 pound
                                                                  price.
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="field-wrapper with-suffix suffix-is-2-chars">
                                                      <input
                                                        name="girth"
                                                        id="girth"
                                                        className="form-control numbersonly customized"
                                                        type="text"
                                                        placeholder="0"
                                                        data-field-suffix="in"
                                                        value={this.state.girth}
                                                        onChange={
                                                          this.handlePackageInfo
                                                        }
                                                      />
                                                      <span className="field-icon field-suffix">
                                                        in
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              <div className="form-button-container">
                                <button
                                  className="previous btn btn-outline-primary"
                                  type="button"
                                  onClick={this.handleBackButton}
                                >
                                  Back
                                </button>
                              </div>
                              {this.displayContinueButton() ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={this.handleContinueButton}
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  };
  render() {
    if (this.state.hasLoaded) {
      switch (this.previousState.flowType) {
        case "Canada":
          return <React.Fragment>{this.renderInternational()}</React.Fragment>;
        case "other":
          return <React.Fragment>{this.renderInternational()}</React.Fragment>;
        case "military":
          return (
            <React.Fragment>{this.renderMilitaryTerritories()}</React.Fragment>
          );
        case "territories":
          return (
            <React.Fragment>{this.renderMilitaryTerritories()}</React.Fragment>
          );
        case "usa":
          return (
            <React.Fragment>{this.renderMilitaryTerritories()}</React.Fragment>
          );
        default:
          if (true) {
            return <React.Fragment>{this.renderMilitaryToUS()}</React.Fragment>;
          }
      }
    } else {
      return null;
    }
  }
}
export default withRouter(PackageInfo);
