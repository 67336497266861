import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import caretRight from "../customs_forms/images/caret-right.svg";
import LoggedInNav from "./LoggedInNav";
import * as analytics from "../Components/Analytics/Analytics.js";
var dataLayer = {
  event: "vpvCFO",
  element: "",
  selection: "",
  page: "cfo-step-6-nondelivery-option"
};
class NonDeliveryOption extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.stepNumberCallback(9);
    this.props.setPageErrors([]);
    this.props.toggleLoadingBar(false);
    this.state = {
      nonDeliveryOption: this.previousState.nonDeliveryOption
        ? this.previousState.nonDeliveryOption.nonDeliveryOption
        : ""
    };
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.recipientInfo
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no recipient info
    window.location.replace("/index");
  };

  handleNonDeliveryOption = (event) => {
    this.setState({
      nonDeliveryOption: event.currentTarget.value
    });
  };

  handleBackButton = () => {
    this.props.history.push("/recipient-info", {
      previousState: this.previousState
    });
    dataLayer.element = "Back";
    analytics.setDataLayerInfo(dataLayer);
  };

  componentDidMount = () => {
    this.props.loginCheck();
  };

  handleContinueButton = (e) => {
    e.preventDefault();
    // this.previousState.nonDeliveryOption = this.state.nonDeliveryOption;
    this.props.toggleLoadingBar(true);
    axios
      .get(
        "/CFOApplication/saveNonDelvOption?cartId=" +
          encodeURIComponent(
            this.props.location.state.previousState.cartInfo.encodedCartId
          ) +
          "&&nonDelvOption=" +
          this.state.nonDeliveryOption
      )
      .then((response) => {
        this.updatePreviousState();
        this.props.history.push("/package-contents", {
          previousState: this.previousState
        });
        dataLayer.element = "Continue";
        analytics.setDataLayerInfo(dataLayer);
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
  };

  updatePreviousState = () => {
    this.previousState.nonDeliveryOption = {
      nonDeliveryOption: this.state.nonDeliveryOption
    };
  };

  render() {
    return (
      <div id="cfo-wrapper">
        <div id="inner">
          <main className="section" id="customs-form-2019-main">
            <section className="section" id="app-form">
              <div className="container">
                <form
                  className="customs-form"
                  id="customs-form"
                  action="#"
                  method="post"
                >
                  <div className="row section-row align-items-center">
                    <div className="col-lg-4">
                      <h1>Customs Form</h1>
                    </div>
                    <div className="col-lg-8">
                      {this.props.loggedIn ? (
                        <LoggedInNav />
                      ) : (
                        <ul className="title-links">
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                            >
                              FAQs{" "}
                              <span>
                                <img src={caretRight} alt="open link" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-9">
                      <p className="note"></p>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-12">
                      <h2>
                        What should we do if your package cannot be delivered to
                        the recipient?
                      </h2>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-12">
                      <p>*Non-delivery Option:</p>
                      <div className="radio-button">
                        <label>
                          <input
                            type="radio"
                            name="nondelivery"
                            value="0"
                            checked={this.state.nonDeliveryOption === "0"}
                            onChange={this.handleNonDeliveryOption}
                          />
                          <span> Return to Sender</span>
                        </label>
                      </div>
                      <div className="radio-button">
                        <label>
                          <input
                            type="radio"
                            name="nondelivery"
                            value="1"
                            checked={this.state.nonDeliveryOption === "1"}
                            onChange={this.handleNonDeliveryOption}
                          />
                          <span> Treat as Abandoned</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row section-row">
                    <div className="col-lg-6">
                      <div className="subsection button-section">
                        <div className="row">
                          <div className="col-12">
                            <div className="customs-form-buttons">
                              <div className="form-button-container">
                                <button
                                  className="previous btn btn-outline-primary"
                                  type="button"
                                  onClick={this.handleBackButton}
                                >
                                  Back
                                </button>
                              </div>
                              {this.state.nonDeliveryOption ? (
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={(e) =>
                                      this.handleContinueButton(e)
                                    }
                                  >
                                    Continue
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(NonDeliveryOption);
