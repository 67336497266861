import React from "react";
import { withRouter } from "react-router-dom";
import LoggedInNav from "./LoggedInNav";
import caretRight from "../customs_forms/images/caret-right.svg";
import axios from "axios";
import InputFields from "./Subcomponents/InputFields";
import StatesTerritoriesList from "./Subcomponents/StatesTerritoriesList";
import AddressBook from "./Modals/AddressBook";
import ReferenceNumberPopover from "./Popovers/ReferenceNumberPopover";
import * as analytics from "../Components/Analytics/Analytics.js";
var dataLayer = {
  event: "vpvCFO",
  element: "",
  selection: "",
  page: "cfo-step-4-sender-information"
};
class SenderInfo extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.stepNumberCallback(7);
    this.props.setPageErrors([]);
    this.props.toggleLoadingBar(false);
    this.flowType = this.previousState.flowType;
    this.flowTypeInputs = this.previousState.flowTypeInputs;
    const senderInfo = this.previousState.senderInfo;
    if (senderInfo) {
      this.state = {
        ...senderInfo,
        errors: {
          firstName: "",
          lastName: "",
          streetAddress: "",
          city: "",
          stateId: "",
          phone: "",
          zipCode: ""
        }
      };
    } else {
      this.state = {
        firstName: "",
        mi: "",
        lastName: "",
        companyName: "",
        addressBook: false,
        streetAddress: "",
        aptSuiteOther: "",
        city: "",
        stateId: "-1",
        zipCode: this.flowTypeInputs.senderZipCode,
        phone: "",
        email: "",
        updateAddress: "",
        referenceNumber: "",
        usedGAB: false,
        errors: {
          firstName: "",
          lastName: "",
          streetAddress: "",
          city: "",
          stateId: "",
          phone: "",
          zipCode: ""
        }
      };
    }
    const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));

    if (accountInfo.loggedIn) {
      this.state.addressBook = true;
    } else {
      this.state.addressBook = false;
    }
  }

  componentDidMount() {
    this.props.loginCheck();
    if (!this.previousState.senderInfo) {
      this.props.toggleLoadingBar(true);
      axios
        .get("/CFOApplication/fetchUserPreferences")
        .then((response) => {
          // Only pre-populate the fields if the user is logged in,
          // has preferences set, and one of the following:
          // 1) the user did not enter a ZIP code in step 2
          // 2) the user entered a ZIP code in step 2
          //    that matches the user's preferred ZIP code
          if (
            !response.data.loggedOut &&
            response.data.preferencesData &&
            response.data.preferencesData.userReturnAddr &&
            (!this.flowTypeInputs.senderZipCode ||
              this.flowTypeInputs.senderZipCode ===
                response.data.preferencesData.userReturnAddr.postalCode)
          ) {
            this.setState({
              referenceNumber: response.data.preferencesData.refNbr || "",
              firstName:
                response.data.preferencesData.userReturnAddr.firstName || "",
              middle:
                response.data.preferencesData.userReturnAddr.middleInit || "",
              lastName:
                response.data.preferencesData.userReturnAddr.lastName || "",
              companyName:
                response.data.preferencesData.userReturnAddr.company || "",
              streetAddress:
                response.data.preferencesData.userReturnAddr.line1Addr || "",
              otherAddress:
                response.data.preferencesData.userReturnAddr.line2Addr || "",
              city: response.data.preferencesData.userReturnAddr.cityName || "",
              state:
                response.data.preferencesData.userReturnAddr.stateCode || "Se",
              stateId:
                response.data.preferencesData.userReturnAddr.stateID || "-1",
              zipCode:
                response.data.preferencesData.userReturnAddr.postalCode || ""
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({
            hasLoaded: true
          });
          this.props.toggleLoadingBar(false);
        });
    }
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.serviceOptions
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no service option
    window.location.replace("/index");
  };

  handleSenderInfo = (event) => {
    const target = event.currentTarget;
    const name = target.name;
    let value;

    if (name === "stateId") {
      this.setState({
        state: event.target.options[target.selectedIndex].innerText.substring(
          0,
          2
        ),
        stateId: event.target.value
      });
      return;
    } else {
      value = event.target.value;
    }

    this.setState((prevState) => {
      return {
        [name]: value,
        errors: {
          ...prevState.errors,
          [name]: ""
        }
      };
    });
  };

  handleBackButton = () => {
    this.props.history.push("/service-options", {
      previousState: this.previousState
    });
    dataLayer.element = "Back";
    analytics.setDataLayerInfo(dataLayer);
  };

  handleContinueButton = (e) => {
    e.preventDefault();
    if (!this.validateInputs()) {
      let dataToSend = {
        cnsCartId: 0,
        strCnsCartId:
          this.props.location.state.previousState.cartInfo.encodedCartId,
        firstName: this.state.firstName
          ? this.state.firstName.replaceAll(/#|&|'/g, "")
          : "",
        middleIntial: this.state.mi
          ? this.state.mi.replaceAll(/#|&|'/g, "")
          : "",
        lastName: this.state.lastName
          ? this.state.lastName.replaceAll(/#|&|'/g, "")
          : "",
        company: this.state.companyName
          ? this.state.companyName.replaceAll(/#|&|'/g, "")
          : "",
        lineOneAddr: this.state.streetAddress
          ? this.state.streetAddress.replaceAll(/#|&|'/g, "")
          : "",
        lineTwoAddr: this.state.aptSuiteOther
          ? this.state.aptSuiteOther.replaceAll(/#|&|'/g, "")
          : "",
        lineThreeAddr: "",
        city: this.state.city ? this.state.city.replaceAll(/#|&|'/g, "") : "",
        state: this.state.state,
        zipCode: this.state.zipCode,
        phone: this.state.phone,
        email: this.state.email,
        referenceNum: this.state.referenceNumber,
        urbanizationCode: "",
        stateId: this.state.stateId,
        flowType: this.props.location.state.previousState.flowType
      };

      this.props.toggleLoadingBar(true);
      if (this.state.updateAddress) {
        const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));
        let sendAddressBook = {};
        sendAddressBook.returnContact = "true";
        sendAddressBook.userId = accountInfo.customerID;
        sendAddressBook.contactId = "-1";
        sendAddressBook.firstName = this.state.firstName;
        sendAddressBook.lastName = this.state.lastName;
        sendAddressBook.companyName = this.state.companyName;
        sendAddressBook.addressId = "-1";
        sendAddressBook.addressLine1 = this.state.streetAddress;
        sendAddressBook.city = this.state.city;
        sendAddressBook.stateCode = this.state.state;
        sendAddressBook.zipCode = this.state.zipCode;
        sendAddressBook.urbanizationCode = "";

        axios
          .post(
            "/CFOApplication/savetoaddressbook",
            JSON.stringify(sendAddressBook),
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
              }
            }
          )
          .then((response) => {
            console.log("success" + response);
          });
      }
      axios
        .post("/CFOApplication/saveSenderInfo", JSON.stringify(dataToSend), {
          headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
          if (typeof response.data !== "string") {
            const returnAddress = response.data.returnAddress;
            this.previousState.senderInfo = {
              firstName: returnAddress.firstName || "",
              mi: returnAddress.middleInit || "",
              lastName: returnAddress.lastName || "",
              companyName: returnAddress.companyName || "",
              streetAddress: returnAddress.line1Addr || "",
              aptSuiteOther: returnAddress.line2Addr || "",
              city: returnAddress.cityName || "",
              state: returnAddress.stateCode || "",
              stateId: String(returnAddress.stateId) || "",
              zipCode:
                returnAddress.postalCode ||
                returnAddress.zip5 ||
                this.flowTypeInputs.senderZipCode ||
                "",
              urbanizationCode: returnAddress.urbanizationCode || "",
              phone: returnAddress.phoneNbr || "",
              email: returnAddress.emailAddress || "",
              referenceNumber: returnAddress.refNbr || ""
            };
            analytics.senderInfo_InfoToDataLayer(this.previousState);
            this.props.history.push("/recipient-info", {
              previousState: this.previousState
            });

            dataLayer.element = "Continue";
            analytics.setDataLayerInfo(dataLayer);
          } else {
            this.props.setPageErrors([response.data]);
            this.props.toggleLoadingBar(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        focusOnError: true
      });
    }
  };
  componentDidUpdate() {
    // Focus on the first field with an error
    if (this.state.focusOnError) {
      if (this.state.errors["firstName"]) {
        document.getElementById("firstName-a11y").focus();
      } else if (this.state.errors["lastName"]) {
        document.getElementById("lastName-a11y").focus();
      } else if (this.state.errors["streetAddress"]) {
        document.getElementById("streetAddress-a11y").focus();
      } else if (this.state.errors["city"]) {
        document.getElementById("city-a11y").focus();
      } else if (this.state.errors["stateId"]) {
        document.getElementById("stateId-a11y").focus();
      } else if (this.state.errors["zipCode"]) {
        document.getElementById("zipCode-a11y").focus();
      } else if (this.state.errors["phone"]) {
        document.getElementById("phone-a11y").focus();
      }
      this.setState({
        focusOnError: false
      });
    }
    // Focus on the top element on the page after first loading
    if (this.state.focusOnTop && this.state.hasLoaded) {
      document.getElementById("cfo-top-of-page").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
  }
  validateInputs = () => {
    let errorObject = {};
    if (!this.state.firstName && !this.state.companyName) {
      errorObject["firstName"] = "Please enter first name.";
    } else if (
      this.state.companyName &&
      this.state.lastName &&
      !this.state.firstName
    ) {
      errorObject["firstName"] = "Please enter first name.";
    } else if (this.state.firstName && this.state.firstName.length < 2) {
      errorObject["firstName"] = "Please enter at least two letters.";
    }
    if (!this.state.lastName && !this.state.companyName) {
      errorObject["lastName"] = "Please enter last name.";
    } else if (
      this.state.companyName &&
      this.state.firstName &&
      !this.state.lastName
    ) {
      errorObject["lastName"] = "Please enter last name.";
    } else if (this.state.lastName && this.state.lastName.length < 2) {
      errorObject["lastName"] = "Please enter at least two letters.";
    }
    if (!this.state.streetAddress) {
      errorObject["streetAddress"] = "Please enter address.";
    }
    if (!this.state.city) {
      errorObject["city"] = "Please enter city.";
    }
    if (!this.state.zipCode) {
      errorObject["zipCode"] = "Please enter ZIP code.";
    }
    if (this.state.stateId === "-1") {
      errorObject["stateId"] = "Please select state.";
    }
    if (!this.state.phone) {
      errorObject["phone"] = "Please enter phone number.";
    }

    if (this.state.phone.length !== 10) {
      errorObject["phone"] = "Please enter a valid phone number.";
    }

    this.setInputErrors("errorObject", errorObject);
    return Object.keys(errorObject).length > 0;
  };

  setInputErrors = (field, errorMessage) => {
    if (field === "errorObject") {
      this.setState({
        errors: errorMessage
      });
    } else {
      this.setState((prevState) => {
        return {
          errors: {
            ...prevState.errors,
            [field]: errorMessage
          }
        };
      });
    }
  };

  gabReturnAddress = (data) => (info) => {
    if (info && info.address) {
      // Clear any existing errors for the fields provided from the address book
      if (info.firstName) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              firstName: ""
            }
          };
        });
      }
      if (info.lastName) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              lastName: ""
            }
          };
        });
      }
      if (info.companyName) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              firstName: "",
              lastName: ""
            }
          };
        });
      }
      if (info.address.addressLine1) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              streetAddress: ""
            }
          };
        });
      }
      if (info.address.city) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              city: ""
            }
          };
        });
      }
      if (info.address.state && info.address.state.stateDbId) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              stateId: ""
            }
          };
        });
      }
      if (info.address.zipCode || info.address.postalCode) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              zipCode: ""
            }
          };
        });
      }
      // Update the fields provided from the address book
      this.setState({
        firstName: info.firstName || "",
        middle: info.middleName || "",
        lastName: info.lastName || "",
        companyName: info.companyName || "",
        streetAddress: info.address.addressLine1 || "",
        otherAddress: info.address.addressLine2 || "",
        city: info.address.city || "",
        state:
          info.address.state && info.address.state.stateCode
            ? info.address.state.stateCode
            : "Se",
        stateId:
          info.address.state && info.address.state.stateDbId
            ? info.address.state.stateDbId
            : "-1",
        zipCode: info.address.zipCode || info.address.postalCode || "",
        referenceNumber: info.address.referenceNumber || "",
        usedGAB: true
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div id="cfo-wrapper">
          <div id="inner">
            <main className="section" id="customs-form-2019-main">
              <section className="section" id="app-form">
                <div className="container">
                  <form
                    className="customs-form"
                    id="customs-form"
                    action="#"
                    method="post"
                  >
                    <div className="row section-row align-items-center">
                      <div className="col-lg-4">
                        <h1>Customs Form</h1>
                        <p className="note">* indicates a required field</p>
                      </div>
                      <div className="col-lg-8">
                        {this.props.loggedIn ? (
                          <LoggedInNav />
                        ) : (
                          <ul className="title-links">
                            <li>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                              >
                                FAQs{" "}
                                <span>
                                  <img src={caretRight} alt="open link" />
                                </span>
                              </a>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="row section-row">
                      <div className="col-lg-8 col-xl-6">
                        <h2>Sender Information</h2>
                      </div>
                    </div>
                    {this.props.loggedIn ? (
                      <div className="row section-row">
                        <div className="col-12">
                          <div className="headline-buttons">
                            <ul>
                              <li>
                                <strong>Return address</strong>
                              </li>

                              <li>
                                <AddressBook
                                  buttonLabel={"Address Book"}
                                  changeDeliveryAddress={this.gabReturnAddress}
                                  addressFieldType={"GABReturn"}
                                  showInternational={"false"}
                                  targetName="btn-open-contact-return"
                                  lookupCodes={this.props.lookupCodes}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <React.Fragment />
                    )}
                    <div className="row section-row">
                      <div className="col-lg-8 col-xl-6">
                        <div className="row">
                          <InputFields
                            outerDivClassList="col-9"
                            isRequired={true}
                            errorMessage={this.state.errors.firstName}
                            includeAssistiveError={true}
                            inputId="firstName"
                            inputClassList="form-control"
                            inputType="text"
                            labelText="First Name"
                            inputValue={this.state.firstName}
                            inputOnChange={this.handleSenderInfo}
                          />
                          <InputFields
                            outerDivClassList="col-sm-3"
                            inputId="mi"
                            labelText="M.I."
                            inputClassList="form-control"
                            inputType="text"
                            inputMaxLength="1"
                            inputValue={this.state.mi}
                            inputOnChange={this.handleSenderInfo}
                          />
                        </div>
                        <div className="row">
                          <InputFields
                            outerDivClassList="col-12"
                            isRequired={true}
                            errorMessage={this.state.errors.lastName}
                            includeAssistiveError={true}
                            inputId="lastName"
                            inputClassList="form-control"
                            inputType="text"
                            labelText="Last Name"
                            inputValue={this.state.lastName}
                            inputOnChange={this.handleSenderInfo}
                          />
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <div className="inline-label-wrapper">
                                <label htmlFor="companyName">
                                  Company (Only required if first and last name
                                  are not provided)
                                </label>
                              </div>
                              <input
                                name="companyName"
                                id="companyName"
                                className="form-control"
                                type="text"
                                value={this.state.companyName}
                                onChange={this.handleSenderInfo}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <InputFields
                            outerDivClassList="col-12"
                            errorMessage={this.state.errors.streetAddress}
                            includeAssistiveError={true}
                            isRequired={true}
                            inputId="streetAddress"
                            labelText="Street Address"
                            inputClassList="form-control"
                            inputType="text"
                            inputValue={this.state.streetAddress}
                            inputOnChange={this.handleSenderInfo}
                          />
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <div className="inline-label-wrapper">
                                <label htmlFor="aptSuiteOther">
                                  Apt/Suite/Other
                                </label>
                              </div>
                              <input
                                name="aptSuiteOther"
                                id="aptSuiteOther"
                                className="form-control"
                                type="text"
                                value={this.state.aptSuiteOther}
                                onChange={this.handleSenderInfo}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <InputFields
                            outerDivClassList="col-12"
                            isRequired={true}
                            errorMessage={this.state.errors.city}
                            includeAssistiveError={true}
                            inputId="city"
                            inputClassList="form-control"
                            inputType="text"
                            labelText="City"
                            inputValue={this.state.city}
                            inputOnChange={this.handleSenderInfo}
                          />
                        </div>
                        <div className="row">
                          <InputFields
                            outerDivClassList="col-sm-9"
                            isRequired={true}
                            inputId="stateId"
                            labelText="State"
                            errorMessage={this.state.errors.stateId}
                            includeAssistiveError={true}
                            elementType="select"
                            inputClassList="form-control dropdown"
                            inputValue={this.state.stateId}
                            inputOnChange={this.handleSenderInfo}
                            optionsList={StatesTerritoriesList}
                          />
                          <InputFields
                            outerDivClassList="col-sm-3"
                            isRequired={true}
                            inputId="zipCode"
                            labelText={
                              <React.Fragment>
                                ZIP Code<sup>&trade;</sup>
                              </React.Fragment>
                            }
                            errorMessage={this.state.errors.zipCode}
                            includeAssistiveError={true}
                            elementType="text"
                            inputClassList="form-control"
                            inputValue={this.state.zipCode}
                            inputPlaceholder="e.g. 75007"
                            inputOnChange={this.handleSenderInfo}
                            inputReadOnly={!!this.flowTypeInputs.senderZipCode}
                          />
                        </div>
                        <div className="row">
                          <InputFields
                            outerDivClassList="col-12"
                            isRequired={true}
                            errorMessage={this.state.errors.phone}
                            includeAssistiveError={true}
                            inputId="phone"
                            inputClassList="form-control"
                            inputType="text"
                            labelText="Phone Number"
                            inputValue={this.state.phone}
                            inputOnChange={this.handleSenderInfo}
                          />
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <div className="inline-label-wrapper">
                                <label htmlFor="email">Email</label>
                              </div>
                              <input
                                name="email"
                                id="email"
                                className="form-control"
                                type="email"
                                value={this.state.email}
                                onChange={this.handleSenderInfo}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <InputFields
                            outerDivClassList="col-12"
                            isRequired={false}
                            inputId="referenceNumber"
                            labelText="Reference Number (if applicable)"
                            infoIcon={
                              <ReferenceNumberPopover targetName="returnReferenceNumberPopover" />
                            }
                            inputClassList="form-control"
                            inputType="text"
                            inputPlaceholder="You may enter up to 10 characters"
                            inputMaxLength="10"
                            inputValue={this.state.referenceNumber}
                            inputOnChange={this.handleSenderInfo}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {this.state.addressBook ? (
                        <div className="col-12">
                          {" "}
                          <div className="form-group mt-2">
                            <div className="usps-checkbox checkbox-field">
                              <input
                                type="checkbox"
                                id="updateAddressCheckbox"
                                name="updateAddressCheckbox"
                                className="form-control checkbox-field"
                                checked={this.state.updateAddress}
                                onChange={(e) => {
                                  this.setState({
                                    updateAddress: e.target.checked
                                  });
                                }}
                              />
                              {this.state.usedGAB && this.props.loggedIn ? (
                                <label
                                  className="color-black"
                                  htmlFor="updateAddressCheckbox"
                                >
                                  Update this address in my address book
                                </label>
                              ) : this.props.loggedIn ? (
                                <label
                                  className="color-black"
                                  htmlFor="updateAddressCheckbox"
                                >
                                  Save this address in my address book
                                </label>
                              ) : (
                                <React.Fragment />
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="row section-row">
                      <div className="col-lg-6">
                        <div className="subsection button-section">
                          <div className="row">
                            <div className="col-12">
                              <div className="customs-form-buttons">
                                <div className="form-button-container">
                                  <button
                                    className="previous btn btn-outline-primary"
                                    type="button"
                                    onClick={this.handleBackButton}
                                  >
                                    Back
                                  </button>
                                </div>
                                <div className="form-button-container">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    id="submit"
                                    onClick={(e) =>
                                      this.handleContinueButton(e)
                                    }
                                  >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SenderInfo);
