import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import caretRight from "../customs_forms/images/caret-right.svg";
import LoggedInNav from "./LoggedInNav";
import TotalItemValuePopover from "./Popovers/TotalItemValuePopover";
import TotalItemWeightPopover from "./Popovers/TotalItemWeightPopover";
import LandedCostEstimate from "./Modals/LandedCostEstimate";
import isDomesticDestination from "../utils/isDomesticDestination";

import * as analytics from "../Components/Analytics/Analytics.js";
import hsTariffCodeUtils from "../utils/hsTariffCodeUtils";

var dataLayer = {
  event: "vpvCFO",
  element: "",
  selection: "",
  weight: "",
  value: "",
  service: "",
  option: "",
  size: "",
  price: "",
  senderAddress: "",
  recipientAddress: "",
  nondeliveryOption: "",
  category: "",
  contentsPkg: [],
  numItem: "",
  totalItemValue: "",
  totalPackageValue: "",
  totalItemWeightEntered: "",
  totalPackageWeight: "",
  exportInfo: "",
  page: "cfo-step-9-summary"
};
class Summary extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.stepNumberCallback(14);
    this.props.setPageErrors([]);
    this.flowType = this.previousState.flowType;
    this.flowTypeInputs = this.previousState.flowTypeInputs;
    this.cartInfo = this.previousState.cartInfo;
    this.packageInfo = this.previousState.packageInfo;
    this.serviceOptions = this.previousState.serviceOptions;
    this.senderInfo = this.previousState.senderInfo;
    this.recipientInfo = this.previousState.recipientInfo;
    this.nonDeliveryOption = this.previousState.nonDeliveryOption;
    this.packageContents = this.previousState.packageContents;
    this.commercialSender = this.previousState.commercialSender;
    this.itemEntry = this.previousState.itemEntry;
    this.aesExemptions = this.previousState.aesExemptions;
    this.hazmatType = this.previousState.hazmatType;
    this.redirectPaths = {
      hazmat: "/hazmat",
      packageInfo: "/package-info",
      serviceOptions: "/service-options",
      senderInfo: "/sender-info",
      recipientInfo: "/recipient-info",
      nonDeliveryOption: "/non-delivery-option",
      packageContents: "/package-contents",
      itemEntry: "/item-entry",
      aesExemptions: "/aes-exemptions",
      thankYou: "/thank-you"
    };
    this.state = {
      printOption: "N",
      landedCostInputs: this.itemEntry.landedCostInputs || null,
      tlcAmounts: this.itemEntry.tlcAmounts || null
    };
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.aesExemptions
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no AES exemption info
    window.location.replace("/index");
  };

  componentDidMount() {
    this.props.loginCheck();
    this.props.toggleLoadingBar(true);
    Promise.all([
      axios.get(
        "/CFOApplication/fetchlabelInfo?cnsCartId=" +
          encodeURIComponent(this.cartInfo.encodedCartId)
      ),
      axios.get("/CFOApplication/fetchUserPreferences")
    ])
      .then((response) => {
        if (response[0].data.labelComplete === "Y") {
          window.location.replace("/index");
        }
        this.setState({
          shipFromCountryCode: response[0].data.returnAddress.countryCode,
          productClass: response[0].data.productId?.productClass,
          printOption:
            !response[1].data.loggedOut &&
            null !== response[1].data.preferencesData &&
            response[1].data.preferencesData.printOptions
              ? response[1].data.preferencesData.printOptions
              : "N",
          tlcEmail:
            response[0].data.returnAddress.emailAddress ||
            response[1]?.data?.preferencesData?.ptsTrackingEmail ||
            this.props.custRegEmail ||
            ""
        });
        this.getTotalItemsValueWeight();
        this.callImc(response[0].data.deliveryAddress);
      })
      .catch((error) => {
        console.log(error);
        this.props.setPageErrors([
          "We're sorry. An unexpected error has occurred."
        ]);
        this.setState({
          printOption: "N"
        });
        this.props.toggleLoadingBar(false);
      });
  }

  callImc = (deliveryAddress) => {
    const countryCode = deliveryAddress.countryCode;
    const stateCode = deliveryAddress.stateCode;
    axios
      .post("/CFOApplication/tlc/imc/hsCode?countryAbbv=" + countryCode)
      .then((response) => {
        this.hsTariffCheck(response, countryCode, stateCode);
      })
      .catch((error) => {
        console.log(error);
        this.hsTariffCheck(null, countryCode, stateCode);
      })
      .finally(() => {
        this.props.toggleLoadingBar(false);
      });
  };

  hsTariffCheck = (response, countryCode, stateCode) => {
    this.setState({
      hasLoaded: true,
      isHsTariffRequired: response?.data?.hsCodeRequired || stateCode === "AE",
      shipToCountryCode: countryCode
    });
  };

  getTotalItemsValueWeight = () => {
    // Update current total items value and total items weight
    this.totalItemsValue = 0;
    this.totalItemsWeightPounds = 0;
    this.totalItemsWeightOunces = 0;
    for (let i in this.itemEntry.itemsList) {
      this.totalItemsValue += Number(this.itemEntry.itemsList[i].unitValue);
      this.totalItemsWeightOunces +=
        Number(this.itemEntry.itemsList[i].weightPounds) * 16 +
        Number(this.itemEntry.itemsList[i].weightOunces);
    }
    this.totalItemsWeightPounds = Math.floor(this.totalItemsWeightOunces / 16);
    this.totalItemsWeightOunces = this.totalItemsWeightOunces % 16;
  };

  handleRedirects = (event, redirectPath) => {
    event.preventDefault();
    this.props.history.push(redirectPath, {
      previousState: this.previousState
    });
    dataLayer.element = "Back";
    analytics.setSummaryToDataLayer(dataLayer);
  };

  // N = standard, Y = thermal (label printer compatible)
  handlePrintOptions = (event) => {
    this.setState({
      printOption: event.currentTarget.value
    });
  };

  handleContinueButton = (e) => {
    e.preventDefault();
    this.props.toggleLoadingBar(true);
    axios
      .post(
        "/CFOApplication/completeLabel?cartId=" +
          encodeURIComponent(this.cartInfo.encodedCartId),
        {
          landedCostInputs: this.state.landedCostInputs,
          tlcAmounts: {
            tlcDuties: this.state.tlcAmounts?.duties || "0.00",
            tlcFees: this.state.tlcAmounts?.fees || "0.00",
            tlcTaxes: this.state.tlcAmounts?.taxes || "0.00"
          }
        }
      )
      .then((response) => {
        this.previousState.printOption = this.state.printOption;
        this.props.history.push(this.redirectPaths.thankYou, {
          previousState: this.previousState
        });
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleLoadingBar(false);
      });
    dataLayer.element = "Print Customs Form";
    dataLayer.weight =
      this.previousState.packageInfo.weightPounds +
      "lb. " +
      this.previousState.packageInfo.weightOunces +
      "oz.";
    dataLayer.value = "$" + this.previousState.packageInfo.totalPackageValue;

    dataLayer.service = this.previousState.serviceOptions.label;
    dataLayer.option = this.previousState.serviceOptions.label;
    dataLayer.size = this.previousState.serviceOptions.serviceDimensions;
    dataLayer.price =
      "$" + this.previousState.serviceOptions.postOfficePriceDisplay;
    var senderState = this.previousState.senderInfo.stateId + "".toUpperCase();
    var recipientState =
      this.previousState.recipientInfo.state + "".toUpperCase();
    //console.log("senderInfo", this.previousState.senderInfo);
    dataLayer.senderAddress =
      this.previousState.senderInfo.city +
      "," +
      senderState +
      " " +
      this.previousState.senderInfo.zipCode;
    dataLayer.recipientAddress =
      this.previousState.recipientInfo.city +
      "," +
      recipientState +
      " " +
      this.previousState.recipientInfo.zipCode;
    dataLayer.nonDeliveryOption =
      this.previousState.nonDeliveryOption.nonDeliveryOption;
    //dataLayer.contents[i].category = item.category;
    let contents = [];
    this.previousState.itemEntry.itemsList.map((item, i) => {
      contents.push({
        numItem: item.itemQty,
        totalItemsValue: item.unitValue,
        totalPackageValue:
          "$" + this.previousState.packageInfo.totalPackageValue,
        totalPackageWeight:
          item.weightPounds + "lb. " + item.weightOunces + "oz.",
        totalItemWeightEntered: ""
      });
    });
    dataLayer.contentsPkg.push(contents);
    dataLayer.exportInfo = "";
    analytics.setSummaryToDataLayer(dataLayer);
  };

  weightEntered = () => {
    return this.poundsEntered() || this.ouncesEntered();
  };

  poundsEntered = () => {
    return this.packageInfo.weightPounds > 0;
  };

  ouncesEntered = () => {
    return this.packageInfo.weightOunces > 0;
  };

  packageValueEntered = () => {
    return this.packageInfo.totalPackageValue > 0;
  };

  dimensionsEntered = () => {
    return (
      this.packageInfo.length > 0 ||
      this.packageInfo.width > 0 ||
      this.packageInfo.height > 0
    );
  };

  handleLandedCostEstimateModal = (event, value) => {
    event.preventDefault();
    this.setState({
      displayLandedCostEstimateModal: value
    });
  };

  toggleCallToLandedCostApi = (value) => {
    this.setState({
      callLandedCostApi: value
    });
  };

  setLandedCostInputs = (landedCostInputs) => {
    this.setState({
      landedCostInputs: landedCostInputs
    });
  };

  setTlcAmounts = (amounts) => {
    if (amounts) {
      const duties = !isNaN(amounts.duties)
        ? amounts.duties.toFixed(2)
        : "0.00";
      const fees = !isNaN(amounts.fees) ? amounts.fees.toFixed(2) : "0.00";
      const taxes = !isNaN(amounts.taxes) ? amounts.taxes.toFixed(2) : "0.00";
      this.setState({
        tlcAmounts: {
          duties: duties,
          fees: fees,
          taxes: taxes,
          sum: (Number(duties) + Number(fees) + Number(taxes)).toFixed(2)
        }
      });
    } else {
      this.setState({
        tlcAmounts: null
      });
    }
  };

  handleTlcEmail = (event) => {
    this.setState({
      tlcEmail: event.currentTarget.value
    });
  };

  // Get the 2-letter country code for the provided country name
  get2LetterCountryCode = (countryName) => {
    let countryCode2Letter = null;
    for (let index in this.props.countryList) {
      if (countryName === this.props.countryList[index].countryName) {
        countryCode2Letter = this.props.countryList[index].alpha2Abbrv;
        break;
      }
    }
    return countryCode2Letter;
  };

  showLandedCostSection = () => {
    return (
      this.state.isHsTariffRequired &&
      !isDomesticDestination(this.previousState.flowType) &&
      hsTariffCodeUtils.hasAtLeastOne(this.itemEntry.itemsList)
    );
  };

  renderPackageInfo = () => {
    return (
      <div className="col-lg-3 col-sm-6 summary-item">
        <div className="row">
          <div className="col-9 col-sm-12 summary-item-text">
            <h3>Package Details</h3>
            <div>
              {this.weightEntered() ? (
                <React.Fragment>
                  <React.Fragment>
                    Package weight:{" "}
                    {this.poundsEntered() && (
                      <React.Fragment>
                        {this.packageInfo.weightPounds} lb.{" "}
                      </React.Fragment>
                    )}
                    {this.ouncesEntered() && (
                      <React.Fragment>
                        {this.packageInfo.weightOunces} oz.
                      </React.Fragment>
                    )}
                  </React.Fragment>
                  <br />
                </React.Fragment>
              ) : null}
              {isDomesticDestination(this.flowType) ? (
                <p>Package value: ${this.totalItemsValue}</p>
              ) : (
                <p>Package value: ${this.packageInfo.totalPackageValue}</p>
              )}
              {this.dimensionsEntered() && (
                <React.Fragment>
                  <span>Length: {this.packageInfo.length || 0} in.</span>
                  <br />
                  <span>Width: {this.packageInfo.width || 0} in.</span>
                  <br />
                  <span>Height: {this.packageInfo.height || 0} in.</span>
                  {this.packageInfo.girth > 0 && (
                    <React.Fragment>
                      <br />
                      <span>Girth: {this.packageInfo.girth} in.</span>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="col-3 col-sm-12 text-right text-sm-left mt-sm-2 summary-item-edit">
            <p>
              <a
                role="button"
                href={this.redirectPaths.packageInfo}
                className="btn-edit"
                onClick={(event) =>
                  this.handleRedirects(event, this.redirectPaths.packageInfo)
                }
              >
                Edit
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderServiceOptions = () => {
    return (
      <div className="col-lg-3 col-sm-6 summary-item">
        <div className="row">
          {this.props.history.location.state.previousState.flowType ===
            "militaryToUSA" &&
          this.props.history.location.state.previousState.receiptCheck2 ===
            "domesticReceipt" ? (
            <div className="col-9 col-sm-12 summary-item-text">
              <h3>Shipping &amp; Pricing</h3>
              <p>Shipping service will be chosen at Military Post Office</p>
            </div>
          ) : (
            <div className="col-9 col-sm-12 summary-item-text">
              <h3>Shipping &amp; Pricing</h3>
              {!this.props.history.location.state.previousState
                .receiptCheck2 ? (
                <p>
                  {this.serviceOptions.label}
                  <br />
                  {this.serviceOptions.serviceDesc && (
                    <React.Fragment>
                      {this.serviceOptions.serviceDesc}
                      <br />
                    </React.Fragment>
                  )}
                  {this.serviceOptions.serviceDimensions ? (
                    <React.Fragment>
                      {this.serviceOptions.serviceDimensions
                        .split(/<br \/>|<br\/>/)
                        .map((line, i) => {
                          return (
                            <React.Fragment key={i}>
                              {line}
                              <br />
                            </React.Fragment>
                          );
                        })}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.serviceOptions.postOfficePriceDisplay ? (
                    <React.Fragment>
                      ${this.serviceOptions.postOfficePriceDisplay.toFixed(2)}
                    </React.Fragment>
                  ) : null}
                </p>
              ) : (
                <p>Select Service at Post Office®</p>
              )}
            </div>
          )}
          <div className="col-3 col-sm-12 text-right text-sm-left mt-sm-2 summary-item-edit">
            <p>
              <a
                href={this.redirectPaths.serviceOptions}
                className="btn-edit"
                onClick={(event) =>
                  this.handleRedirects(event, this.redirectPaths.serviceOptions)
                }
              >
                Edit
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderSenderInfo = () => {
    return (
      <div className="col-lg-3 col-sm-6 summary-item">
        <div className="row">
          <div className="col-9 col-sm-12 summary-item-text">
            <h3>Sender Address</h3>
            <p>
              {this.senderInfo.firstName ? (
                <React.Fragment>
                  {this.senderInfo.firstName}{" "}
                  {this.senderInfo.mi ? this.senderInfo.mi + ". " : ""}
                  {this.senderInfo.lastName ? (
                    <React.Fragment>{this.senderInfo.lastName}</React.Fragment>
                  ) : null}
                  <br />
                </React.Fragment>
              ) : null}
              {this.senderInfo.companyName ? (
                <React.Fragment>
                  {this.senderInfo.companyName}
                  <br />
                </React.Fragment>
              ) : null}
              {this.senderInfo.streetAddress}
              <br />
              {this.senderInfo.aptSuiteOther ? (
                <React.Fragment>
                  {this.senderInfo.aptSuiteOther}
                  <br />
                </React.Fragment>
              ) : null}
              {this.senderInfo.city +
                ", " +
                this.senderInfo.state +
                " " +
                this.senderInfo.zipCode}
              <br />
              {this.senderInfo.phone.substring(0, 3) +
                "-" +
                this.senderInfo.phone.substring(3, 6) +
                "-" +
                this.senderInfo.phone.substring(6)}
              <br />
              {this.senderInfo.email || null}
            </p>
          </div>
          <div className="col-3 col-sm-12 text-right text-sm-left mt-sm-2 summary-item-edit">
            <p>
              <a
                href={this.redirectPaths.senderInfo}
                className="btn-edit"
                onClick={(event) =>
                  this.handleRedirects(event, this.redirectPaths.senderInfo)
                }
              >
                Edit
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderRecipientInfo = () => {
    return (
      <div className="col-lg-3 col-sm-6 summary-item">
        <div className="row">
          <div className="col-9 col-sm-12 summary-item-text">
            <h3>Recipient Address</h3>
            <p>
              {this.recipientInfo.firstName ? (
                <React.Fragment>
                  {this.recipientInfo.firstName}{" "}
                  {this.recipientInfo.mi ? this.recipientInfo.mi + ". " : ""}
                  {this.recipientInfo.lastName ? (
                    <React.Fragment>
                      {this.recipientInfo.lastName}
                    </React.Fragment>
                  ) : null}
                  <br />
                </React.Fragment>
              ) : null}
              {this.recipientInfo.companyName ? (
                <React.Fragment>
                  {this.recipientInfo.companyName}
                  <br />
                </React.Fragment>
              ) : null}
              {this.recipientInfo.streetAddress}
              <br />
              {this.recipientInfo.address2 ? (
                <React.Fragment>
                  {this.recipientInfo.address2}
                  <br />
                </React.Fragment>
              ) : null}
              {this.recipientInfo.address3 ? (
                <React.Fragment>
                  {this.recipientInfo.address3}
                  <br />
                </React.Fragment>
              ) : null}
              {this.recipientInfo.city + ", "}
              {!isDomesticDestination(this.flowType) &&
              this.recipientInfo.province
                ? this.recipientInfo.province + " "
                : ""}
              {isDomesticDestination(this.flowType) ? (
                <React.Fragment>{this.recipientInfo.state}</React.Fragment>
              ) : (
                <React.Fragment>
                  {this.flowTypeInputs.countryName}
                </React.Fragment>
              )}
              {this.recipientInfo.zipCode
                ? " " + this.recipientInfo.zipCode
                : ""}
              {this.recipientInfo.phone ? (
                <React.Fragment>
                  <br />
                  {this.recipientInfo.phone}
                </React.Fragment>
              ) : null}
              {this.recipientInfo.email ? (
                <React.Fragment>
                  <br />
                  {this.recipientInfo.email}
                </React.Fragment>
              ) : null}
            </p>
          </div>
          <div className="col-3 col-sm-12 text-right text-sm-left mt-sm-2 summary-item-edit">
            <p>
              <a
                href={this.redirectPaths.recipientInfo}
                className="btn-edit"
                onClick={(event) =>
                  this.handleRedirects(event, this.redirectPaths.recipientInfo)
                }
              >
                Edit
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderNondeliveryOption = () => {
    return (
      <div className="col-lg-3 col-sm-6 summary-item">
        <div className="row">
          <div className="col-9 col-sm-12 summary-item-text">
            <h3>Non-delivery Option</h3>
            <p>
              {this.previousState.nonDeliveryOption.nonDeliveryOption === "0"
                ? "Return to Sender"
                : this.previousState.nonDeliveryOption.nonDeliveryOption === "1"
                ? "Treat as Abandoned"
                : "No Data"}
            </p>
          </div>
          <div className="col-3 col-sm-12 text-right text-sm-left mt-sm-2 summary-item-edit">
            <p>
              <a
                href={this.redirectPaths.nonDeliveryOption}
                className="btn-edit"
                onClick={(event) =>
                  this.handleRedirects(
                    event,
                    this.redirectPaths.nonDeliveryOption
                  )
                }
              >
                Edit
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderPackageContents = () => {
    return (
      <div className="col-lg-3 col-sm-6 summary-item">
        <div className="row">
          <div className="col-9 col-sm-12 summary-item-text">
            <h3>Package Contents</h3>
            <p>{this.previousState.packageContents.category}</p>
          </div>
          <div className="col-3 col-sm-12 text-right text-sm-left mt-sm-2 summary-item-edit">
            <p>
              <a
                href={this.redirectPaths.packageContents}
                className="btn-edit"
                onClick={(event) =>
                  this.handleRedirects(
                    event,
                    this.redirectPaths.packageContents
                  )
                }
              >
                Edit
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderExportInfo = () => {
    return (
      <div className="col-lg-3 col-sm-6 summary-item summary-item-large">
        <div className="row">
          <div className="col-9 col-sm-12 summary-item-text">
            <h3>Export Information</h3>
            <p>
              {this.previousState.aesExemptions.aesItn
                ? this.previousState.aesExemptions.aesItn
                : this.previousState.aesExemptions.aesManualEntry
                ? "AES Exemption: " +
                  this.previousState.aesExemptions.aesManualEntry
                : "AES Exemption: " + this.previousState.aesExemptions.eelCode}
            </p>
          </div>
          <div className="col-3 col-sm-12 text-right text-sm-left mt-sm-2 summary-item-edit">
            <p>
              <a
                href={this.redirectPaths.aesExemptions}
                className="btn-edit"
                onClick={(event) =>
                  this.handleRedirects(event, this.redirectPaths.aesExemptions)
                }
              >
                Edit
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderHazmatInfo = () => {
    return (
      <div className="col-lg-3 col-sm-6 summary-item summary-item-large">
        <div className="row">
          <div className="col-3 col-sm-12 summary-item-text">
            <h3>Hazmat Information</h3>
            <p>
              {this.hazmatType !== null && this.hazmatType !== undefined ? (
                <p>Yes</p>
              ) : (
                <p>No</p>
              )}
            </p>
          </div>
          <div className="col-3 col-sm-12 text-right text-sm-left mt-sm-2 summary-item-edit">
            <a
              href={this.redirectPaths.hazmat}
              className="btn-edit"
              onClick={(event) =>
                this.handleRedirects(event, this.redirectPaths.hazmat)
              }
            >
              Edit
            </a>
          </div>
        </div>
      </div>
    );
  };

  renderTlc = () => {
    return (
      <div className="col-lg-3 col-sm-6 summary-item">
        <div className="row">
          <div className="col-9 col-sm-12 summary-item-text">
            <h3>Total Landed Cost</h3>
            <p id="summary-tlc-text">Estimated amount paid by recipient</p>
            {this.state.tlcAmounts?.sum && (
              <h3>${this.state.tlcAmounts.sum}</h3>
            )}
          </div>
          <div className="col-3 col-sm-12 text-right text-sm-left mt-sm-2 summary-item-view">
            <p>
              <button
                id="summary-view-tlc"
                className="button-link"
                onClick={(e) => {
                  this.handleLandedCostEstimateModal(e, true);
                  this.toggleCallToLandedCostApi(true);
                }}
              >
                View
              </button>
              <LandedCostEstimate
                isOpen={this.state.displayLandedCostEstimateModal}
                toggle={this.handleLandedCostEstimateModal}
                callLandedCostApi={this.state.callLandedCostApi}
                toggleCallToLandedCostApi={this.toggleCallToLandedCostApi}
                items={this.itemEntry.itemsList}
                returnAddressCountryCode={this.state.shipFromCountryCode}
                deliveryAddress={{
                  ...this.recipientInfo,
                  countryCode: this.state.shipToCountryCode
                }}
                serviceInfo={{
                  ...this.serviceOptions,
                  productClass: this.state.productClass
                }}
                landedCostInputs={this.state.landedCostInputs}
                setLandedCostInputs={this.setLandedCostInputs}
                tlcAmounts={this.state.tlcAmounts}
                setTlcAmounts={this.setTlcAmounts}
                cartId={this.cartInfo.encodedCartId}
                tlcEmail={this.state.tlcEmail}
                handleTlcEmail={this.handleTlcEmail}
                toggleLoadingBar={this.props.toggleLoadingBar}
                get2LetterCountryCode={this.get2LetterCountryCode}
              />
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderItemInfo = () => {
    return (
      <div className="col-12">
        <div className="row section-row">
          <div className="col-12">
            <h2 className="has-edit-button">Items in Your Package</h2>
            <a
              className="btn-edit float-right float-sm-none"
              href={this.redirectPaths.itemEntry}
              onClick={(event) =>
                this.handleRedirects(event, this.redirectPaths.itemEntry)
              }
            >
              Edit
            </a>
          </div>
        </div>
        <div className="row section-row">
          <div className="col-lg-10">
            <div className="row section-row items-table">
              <div className="col-12">
                <div className="row items-row items-row-header align-items-center">
                  <div className="col-xl-1 item-cell ic-item-number">
                    Item #
                  </div>
                  <div className="col-xl-11 ic-right-col">
                    <div className="row">
                      <div className="col-12">
                        <div className="row items-row-inner items-row-inner-top">
                          <div className="col-sm-4 col-xl-6 item-cell ic-item-description">
                            * Description
                          </div>
                          <div className="col-sm-4 col-xl-1 item-cell ic-item-quantity">
                            * Qty
                          </div>
                          <div className="col-sm-4 col-xl-2 item-cell ic-item-value">
                            * Value
                          </div>
                          <div className="col-xl-3 item-cell ic-item-weight">
                            Weight
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.itemEntry.itemsList.map(this.renderItemRow)}

                <div className="row items-row items-subtotal-row">
                  <div className="col-xl-1 d-none d-xl-flex">&nbsp;</div>
                  <div className="col-xl-11">
                    <div className="row">
                      <div className="col-6 col-lg-9 item-cell ic-item-total-value">
                        <span className="ic-label">
                          Total Value{" "}
                          <span
                            className="more-info-link modal-trigger"
                            data-toggle="modal"
                            data-target="#modal-total-value"
                          >
                            <TotalItemValuePopover targetName="returnITotalItemValuePopover" />
                          </span>
                        </span>
                        <div className="ic-data">
                          $
                          <span className="js-value" id="total-value-reported">
                            {this.totalItemsValue}
                          </span>
                        </div>
                        <div
                          className="more-info-content modal fade"
                          id="modal-total-value"
                        >
                          <div
                            className="modal-dialog modal-lg"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-body">
                                <p>
                                  Total value of all of the items you have added
                                  here.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-lg-3 item-cell ic-item-total-weight">
                        <span className="ic-label">
                          Total Items Weight{" "}
                          <span className="items-weight-entered-text">
                            Entered
                          </span>
                        </span>
                        <div className="more-info">
                          <span
                            className="more-info-link modal-trigger"
                            data-toggle="modal"
                            data-target="#modal-total-weight"
                          >
                            <TotalItemWeightPopover targetName="returnTotalItemWeightPopover" />
                            {/* <img
                              className="tooltip-icon"
                              src={info}
                              alt="More info"
                              width="18"
                              height="18"
                            /> */}
                          </span>
                          <div
                            className="more-info-content modal fade"
                            id="modal-total-weight"
                          >
                            <div
                              className="modal-dialog modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-body">
                                  <p>
                                    Total weight of all of the items you have
                                    added here.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ic-data">
                          {this.totalItemsWeightPounds > 0 ||
                          this.totalItemsWeightOunces > 0 ? (
                            <span className="items-weight-value">
                              <span
                                className="js-value"
                                id="total-weight-pounds-reported"
                              >
                                {this.totalItemsWeightPounds}
                              </span>{" "}
                              lbs.{" "}
                              <span
                                className="js-value"
                                id="total-weight-ounces-reported"
                              >
                                {this.totalItemsWeightOunces}
                              </span>{" "}
                              oz.
                            </span>
                          ) : (
                            <span className="items-weight-error">
                              No item weight entered.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row items-row items-subtotal-row items-final-row">
                  <div className="col-xl-1 d-none d-xl-flex">&nbsp;</div>
                  <div className="col-xl-11">
                    <div className="row">
                      <div className="col-6 col-lg-9 item-cell ic-item-total-value">
                        <span className="ic-label">Total Package Value</span>
                        <br />
                        <span className="ic-data">
                          $
                          <span id="total-package-value">
                            {this.packageInfo.totalPackageValue}
                          </span>
                        </span>
                      </div>
                      <div className="col-6 col-lg-3 item-cell ic-item-total-weight">
                        <span className="ic-label">Total Package Weight</span>
                        <br />
                        <span className="ic-data">
                          <span id="total-package-weight-pounds">
                            {this.packageInfo.weightPounds}
                          </span>{" "}
                          lbs.{" "}
                          <span id="total-package-weight-ounces">
                            {this.packageInfo.weightOunces}
                          </span>{" "}
                          oz.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderItemRow = (item, index) => {
    index++;

    return (
      <div
        key={item.key || index}
        className="row items-row items-row-item row-locked"
      >
        <div className="col-xl-1 item-cell ic-item-number">
          <span className="ic-item-number-id pt-0">{index}</span>
        </div>
        <div className="col-xl-11 ic-right-col">
          <div className="row flex-lg-column-reverse">
            <div className="col-12 bottom-row-placeholder-container">
              {item.hsTariffNbr || item.countryOfOrigin ? (
                <div className="row items-row-inner items-row-inner-commercial">
                  <div className="col-sm-6 col-xl-3 item-cell ic-item-tariff">
                    <div className="line-label">HS Tariff #</div>
                    <div className="line-data">{item.hsTariffNbr}</div>
                  </div>
                  <div className="col-sm-6 col-xl-3 item-cell ic-item-origin-country">
                    <div className="line-label">Country of Origin</div>
                    <div className="line-data">{item.countryOfOrigin}</div>
                  </div>
                </div>
              ) : (
                <div className="bottom-row-placeholder"></div>
              )}
            </div>
            <div className="col-12">
              <div className="row items-row-inner items-row-inner-top">
                <div className="col-sm-4 col-xl-6 item-cell ic-item-description">
                  {item.itemDesc}
                </div>
                <div className="col-sm-4 col-xl-1 item-cell ic-item-quantity">
                  {item.itemQty}
                </div>
                <div className="col-sm-4 col-xl-2 item-cell ic-item-value">
                  {item.unitValue ? "$" + item.unitValue : ""}
                </div>
                <div className="col-xl-3 item-cell ic-item-weight">
                  {item.weightPounds || item.weightOunces
                    ? item.weightPounds + " lbs. " + item.weightOunces + " oz."
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPrintOptions = () => {
    return (
      <React.Fragment>
        <div className="row section-row">
          <div className="col-12">
            <h2>Print Options</h2>
          </div>
        </div>
        <div className="row section-row">
          <div className="col-12">
            <div className="radio-button">
              <label>
                <div className="radio-input">
                  <input
                    type="radio"
                    name="print"
                    value="N"
                    checked={this.state.printOption === "N"}
                    onChange={this.handlePrintOptions}
                  />
                </div>
                <div className="radio-label">
                  Standard (8.5" X 11")
                  <br />2 pages sheet
                </div>
              </label>
            </div>
            <div className="radio-button">
              <label>
                <div className="radio-input">
                  <input
                    type="radio"
                    name="print"
                    value="Y"
                    checked={this.state.printOption === "Y"}
                    onChange={this.handlePrintOptions}
                  />
                </div>
                <div className="radio-label">
                  Label Printer Compatible (4" X 6")
                  <br />1 page sheet
                </div>
              </label>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.hasLoaded ? (
          <div id="cfo-wrapper">
            <div id="inner">
              <main className="section" id="customs-form-2019-main">
                <section className="section" id="app-form">
                  <div className="container">
                    <form
                      className="customs-form"
                      id="customs-form"
                      action="#"
                      method="post"
                    >
                      <div className="row section-row align-items-center">
                        <div className="col-lg-4">
                          <h1>Customs Form</h1>
                        </div>
                        <div className="col-lg-8">
                          {this.props.loggedIn ? (
                            <LoggedInNav />
                          ) : (
                            <ul className="title-links">
                              <li>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                                >
                                  FAQs{" "}
                                  <span>
                                    <img src={caretRight} alt="open link" />
                                  </span>
                                </a>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-12">
                          <h2>Customs Form 2976A-Summary</h2>
                          <p>
                            Please confirm the information you have entered. To
                            edit your information, click the appropriate 'Edit'
                            link to make changes. <br />
                            Then click on the 'Print Customs Form' button to
                            create your completed Customs Form.
                          </p>
                        </div>
                      </div>
                      <div className="row summary-row">
                        <div className="col-12">
                          <div className="row summary-row">
                            {this.renderPackageInfo()}
                            {this.renderServiceOptions()}
                            {this.renderSenderInfo()}
                            {this.renderRecipientInfo()}
                          </div>
                          <div className="row summary-row">
                            {this.renderNondeliveryOption()}
                            {this.renderPackageContents()}
                            {this.renderExportInfo()}
                            {this.renderHazmatInfo()}
                            {this.showLandedCostSection() && this.renderTlc()}
                            {/* need new columns for TLC, then HAZMAT (if both, HAZMAT goes below on a new row)*/}
                            {/* {this.renderHazmatInfo()} */}
                          </div>
                        </div>
                      </div>
                      <div className="row summary-row items-in-your-package">
                        {this.renderItemInfo()}
                      </div>
                      {this.renderPrintOptions()}
                      <div className="row section-row">
                        <div className="col-lg-6">
                          <div className="subsection button-section">
                            <div className="row">
                              <div className="col-12">
                                <div className="customs-form-buttons">
                                  <div className="form-button-container">
                                    <button
                                      className="previous btn btn-outline-primary"
                                      type="button"
                                      onClick={(event) =>
                                        this.handleRedirects(
                                          event,
                                          this.redirectPaths.aesExemptions
                                        )
                                      }
                                    >
                                      Back
                                    </button>
                                  </div>
                                  <div className="form-button-container">
                                    <button
                                      type="submit"
                                      className="btn btn-primary force-show"
                                      id="submit"
                                      onClick={(e) =>
                                        this.handleContinueButton(e)
                                      }
                                    >
                                      {this.flowType === "militaryToUSA" &&
                                      this.props.history.location.state
                                        .previousState.receiptCheck2
                                        ? "Print Military Receipt"
                                        : "Print Customs Form"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </main>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(Summary);
